import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnDestroy {

  newUnseenConversation = false;
  onChatActive = false;
  
  private socket: Socket | null = null;
  private destroy$ = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        this.socket = io(environment.socketURL); // Initialize socket
      } catch (error) {
        console.error('Error initializing socket:', error);
      }
    }
  }

  joinRoom(userId: any): void {
    if (this.socket) {
      this.socket.emit('join', userId);
    } else {
      console.error('Socket not initialized.');
    }
  }

  joinChat(convId: any): void {
    if (this.socket) {
      this.socket.emit('joinChat', convId);
    } else {
      console.error('Socket not initialized.');
    }
  }

  sendMessage(data: any): void {
    
    if (this.socket) {
      this.socket.emit('message', data);
    } else {
      console.error('Socket not initialized.');
    }
  }

  receiveMessages(): Observable<any> {
    return new Observable(observer => {
      if (this.socket) {
        this.socket.on('message-response', (data: any) => {
          observer.next(data);
        });
      } else {
        console.error('Socket not initialized.');
      }
      return () => {
        if (this.socket) {
          this.socket.disconnect();
        }
      }
    });
  }

  checkMessages(): Observable<any> {
    return new Observable(observer => {
      if (this.socket) {
        this.socket.on('new-message', (data: any) => {
          observer.next(data);
        });
      } else {
        console.error('Socket not initialized.');
      }
      return () => {
        if (this.socket) {
          this.socket.disconnect();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

}
