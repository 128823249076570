import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
// import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // private socket: Socket
  public projects: any = [];
  services: any = [];
  servicesByCategory: any = []


  constructor(public apiS: ApiService, public authS: AuthService) {
    this.getData();
  }

  getData() {
    if (this.authS.currentUserValue) {
      this.apiS.getProject({ user: this.authS.currentUserValue.id }, 1, 20, "").subscribe(data => {
        this.projects = data.data;

      })
    }
  }

  async getServices(): Promise<Observable<any>> {
    if (this.services.length > 0) {
      return of(this.services);
    } else {
      this.services = await this.apiS.getService({}, 1, 100, "").toPromise();
      return of(this.services);
    }
  }

  async getServicesByCategory(): Promise<Observable<any>> {
    if (this.servicesByCategory.length > 0) {
      return of(this.servicesByCategory);
    } else {
      await this.getServices();
      let servicesData = this.services.data;
      this.servicesByCategory = this.groupServicesByCategory(servicesData);
      this.servicesByCategory.sort((a: any, b: any) => a.key.localeCompare(b.key));
      return of(this.servicesByCategory);
    }
  }

  groupServicesByCategory(array: any): any[] {
    const grouped: any = {};
    for (const service of array) {
      const category = service.category;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(service);
    }
    return Object.entries(grouped).map(([key, value]: any) => ({
      key,
      value: value.sort((a: any, b: any) => a.title.localeCompare(b.title))
    }));
  }
}
