import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { DOCUMENT, isPlatformBrowser,Location } from '@angular/common';
import { ActivatedRoute, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Modal } from 'flowbite';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { Services } from './core/models/services';
import { ApiService } from './core/services/api.service';
import { AuthService } from './core/services/auth.service';
import { ChatService } from './core/services/chat.service';
import { DataService } from './core/services/data.service';
import { PresenceService } from './core/services/presence.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { initModals } from 'flowbite';
import { Dimensions, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  @ViewChild('scrollMe', { read: ElementRef }) public myScrollContainer!: ElementRef<any>;
  title = 'foot2feet';


  userName = "";
  userEmail = "";
  userMobile:any = "";
  userProfession = "";
  userAddress = "";
  userState = "";
  userCity ="";
  updateUserError = false;

  servicesData = Services;
  newServiceRequest = "";
  targetServiceModal:any
  serviceRequestModal:any = false;
  targetcalculatorModal:any;
  calculatorModal:any;
  targetpartnerappModal:any;
  partnerappModal:any;
  newProject="";
  getProjects:any=[];

  targetquicklinksmodal:any;
  quicklinksmodal:any;

  targetaddprojectModal:any;
  addprojectModal:any;

  targetaddserviceRequestModal:any;
  addserviceRequestModal:any;

  targetprofilePhotoModal:any;
  profilePhotoModal:any;

  targetaddcalculatorComplainModal:any;
  addcalculatorComplainModal:any;

  isUserLogged = false;
  isSidemenu = false;
  proOpen = false;
  calOpen = false;
  profileOpen = false;
  notificationOpen = false;
  updateProfileOpen = false;
  isMessaging = false;

  idleState = 'Not started.';
  timedOut = false;
  selectedChat:any ;
  user:any;
  profilePhoto:any = "";

  chatMessagesData: any = [];
  notifications: any = [];
  convId: any = "";
  serviceName: any = "";
  serviceStatus: any = "";
  latestMessage:any="";
  chatData: any = [];
  chatIndex:any = "";
  chatService:any = {};
  receiverId = "";
  isFlag: boolean = false;
  isGroup: boolean = false;
  submitted: boolean = false;
  isEmojy: boolean = false;
  username: any = '';
  mobile:any='';
  groupName: any = '';
  isStatus: string = 'online';
  isProfile: string = 'assets/images/users/avatar-2.jpg';
  typedMessage: any = "";
  isOpen: boolean = false;
  msgLoading: boolean = false;
  isLoading = false;
  isModalOpen =false;
  edit = false;
  limit = 25;
  page = 1;
  totalPages = 1;
  searchValue = "";
  item:any;
  baseURL = environment.baseURL;
  mlimit = 25;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  unreadNotifications = 0;
  showCropper = false;
  containWithinAspectRatio = false;
  transform = {};
  croppedImageFile:any;
  message:any = "";

  capturePhotoS =false;

  constructor(public chatSocket:ChatService,public locationS: Location, public auth: AuthService, public api: ApiService, public titleS: Title, private idle: Idle, public presence: PresenceService, private route: ActivatedRoute,
    public dataS: DataService, public toastr: ToastrService, public router: Router, @Inject(DOCUMENT) public document: Document, @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit(): void {
    
    if (isPlatformBrowser(this.platformId)) {
      // Initialize Flowbite components here
      initModals();

      this.chatSocket.onChatActive = false;
      this.chatSocket.checkMessages().subscribe((socketResponse:any) => {
        if(!this.chatSocket.onChatActive){
          this.chatSocket.newUnseenConversation = true;
          // this.playAudio();
          this.titleS.setTitle("New Message Arrived");
        }
      })
      if(this.auth.currentUserValue){
        this.isUserLogged = true;
        this.user = this.auth.currentUserValue.id;
        this.profilePhoto = this.auth.currentUserValue.photo;

        this.idle.setIdle(300);
        this.idle.setTimeout(900);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => {
          this.presence.online(this.user);
          this.reset();
        })

        this.idle.onTimeout.subscribe(() => {
          this.auth.logout();
        })

        this.idle.onIdleStart.subscribe(() => {
          this.presence.offline(this.user);
        })

        this.reset();
        this.chatSocket.joinRoom(this.auth.currentUserValue.id);

        this.chatSocket.receiveMessages().subscribe((socketResponse: any) => {
          this.chatMessagesData.push(socketResponse);
          setTimeout(() => { this.onListScroll(), 500 });
          this.playNewResponseAudio();
        });

        this.chatSocket.checkMessages().subscribe((socketResponse: any) => {
          
          if (this.convId != socketResponse.conversation) {
            for (let i = 0; i < this.chatData.length; i++) {

              if (this.chatData[i].conversationId == socketResponse.conversation) {
                this.chatData[i].newMessage = true;
                this.chatData[i].newMessageCount = Number(this.chatData[i].newMessageCount) + 1;
                this.chatData[i].lastMessage = socketResponse.message;
                this.chatData[i].lastMessageDate = socketResponse.createdAt;
                this.playAudio();
              }
            }
          }
        });
        this._fetchData();

      }
    }
    // initModals();
    // this.targetServiceModal = this.document.getElementById('serviceRequestModal');
    // this.serviceRequestModal = new Modal(this.targetServiceModal);
    this.targetcalculatorModal =  this.document.getElementById('calculatorModal');
    this.calculatorModal = new Modal(this.targetcalculatorModal);
    this.targetquicklinksmodal =  this.document.getElementById('quicklinksmodal');
    this.quicklinksmodal = new Modal(this.targetquicklinksmodal);

    this.targetpartnerappModal =  this.document.getElementById('partnerappModal');
    this.partnerappModal = new Modal(this.targetpartnerappModal);
    this.targetaddprojectModal =  this.document.getElementById('addprojectModal');
    this.addprojectModal = new Modal(this.targetaddprojectModal);

    this.targetaddserviceRequestModal =  this.document.getElementById('addserviceRequestModal');
    this.addserviceRequestModal = new Modal(this.targetaddserviceRequestModal);
    this.targetprofilePhotoModal =  this.document.getElementById('profilePhotoModal');
    this.profilePhotoModal = new Modal(this.targetprofilePhotoModal);

    this.targetaddcalculatorComplainModal =  this.document.getElementById('addcalculatorComplainModal');
    this.addcalculatorComplainModal = new Modal(this.targetaddcalculatorComplainModal);
    this.auth.currentUser.subscribe(res=>{
      if(res){
        
        this.userName = res.name;
        this.userEmail = res.email;
        this.userMobile = res.mobile;
        this.userProfession = res.profession;
        this.userAddress = res.address;
        this.userState = res.state;
        this.userCity = res.city;
      }
    })
  }

  stopSidemenuModal(event: MouseEvent){
    event.stopPropagation();
  }

  async _fetchData() {
    this.fetchConversation();
    await this.dataS.getServices()
    this.api.getNotification({userId:this.auth.currentUserValue.id},1,20,"","").subscribe(res => {
      let data = res.data;
      this.notifications = data;
      
      this.unreadNotifications = this.notifications.reduce((count:any, obj:any) => !obj.isRead ? count + 1 : count, 0);
    })

  }


  fetchConversation() {
    this.api.getConversation({ members: { $in: [this.auth.currentUserValue.id] } }, 1, 1000, "").subscribe(res => {

      let data = res.data;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].group) {
          for (let j = 0; j < data[i].members.length; j++) {
            let member = data[i].members[j];
            if (member._id.toString() != this.auth.currentUserValue.id.toString()) {
              this.chatData.push({ conversationId: data[i]._id, name: member.name,mobile:member.mobile, status: member.presence, userId: member._id, photo: member.photo, newMessage: data[i].newMessage, newMessageCount: data[i].newMessageCount, lastMessageBy: data[i].lastMessageBy, service: data[i].serviceRequest,proposal: data[i].proposal, serviceStatus: data[i].serviceRequest?.status,lastMessage:data[i].lastMessage,lastMessageDate:data[i].lastMessageDate });
            }
          }
        }

      }

    });
  }

  chatUsername(name: string, profile: any, status: string, convId: string, userId: any, chatIndex: any, service: any, serviceStatus: any) {
    this.chatData[chatIndex].newMessage = false;
    this.chatData[chatIndex].newMessageCount = 0;
    this.chatService = this.chatData[chatIndex].proposal;

    this.chatSocket.joinChat(convId);
    this.receiverId = userId;
    this.convId = convId;
    this.isFlag = true;
    this.username = name;
    this.mobile=this.chatData[chatIndex].mobile;
    
    this.isGroup = false;
    this.serviceName = service.displayServiceText;
    this.serviceStatus = serviceStatus;
    this.chatMessagesData = [];
    this.isStatus = status;
    this.isOpen = true;
    this.chatIndex = chatIndex;
    this.isProfile = this.baseURL + '/file/retrieve/' + profile;

    
    
    this.getMessages();
    

    if (((this.chatData[chatIndex].lastMessageBy != undefined) ? this.chatData[chatIndex].lastMessageBy.toString() : "") != this.auth.currentUserValue.id.toString()) {
      const updateNewMessageData = JSON.stringify({ "newMessage": false, "newMessageCount": 0 });

      this.api.updateConversation(updateNewMessageData, convId).subscribe(res => {
        if (res.status == 'success') {

        }
      }, error => {

      });
    }

  }

  getMessages(){
    let messages:any = [];
    this.api.getMessages({ conversation: this.convId }, 1, this.mlimit, "").subscribe(res => {
      let data = res.data;
      

      for (let i = 0; i < data.length; i++) {
        if (data[i].author._id.toString() == this.auth.currentUserValue.id.toString()) {
          data[i].align = "right";
        } else {
          data[i].align = "left";
        }
        messages.push(data[i]);
      }
      setTimeout(() => {
        this.chatMessagesData = messages.reverse(); 
        
        this.onListScroll();
      }, 500 )
     
    });
  }

  loadMore(){
    this.mlimit = Number(this.mlimit) + 25;
    this.chatMessagesData = [];
    this.getMessages();
  }


  verifyTextMessage(inputMessage: any) {
    var ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
    ];
    if (inputMessage.match(ranges.join('|'))) {

    } else {

    }
  }


  messageSave() {
    this.msgLoading = true;
    const message = this.typedMessage;
    const currentDate = new Date();
    if (message != "") {
      this.verifyTextMessage(message);
      const data = {
        message: message,
        type: "Text",
        author: this.auth.currentUserValue.id,
        conversation: this.convId,
        receiverId: this.receiverId
      };

      this.chatData[this.chatIndex].lastMessage = message;
      this.chatData[this.chatIndex].lastMessageDate = new Date();
      this.chatSocket.sendMessage(data);
      let author = { name: this.auth.currentUserValue.name, photo: this.auth.currentUserValue.photo };
      this.chatMessagesData.push({
        align: 'right',
        author: author,
        message,
        createdAt: currentDate,
      });



      setTimeout(() => { this.msgLoading = false; this.onListScroll(), 500 })


      this.typedMessage = "";
    }
    this.submitted = true;


  }

  onEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.keyCode === 13) {
      // Call your function here
      this.messageSave();
    }
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/newMessage.mp3";
    audio.load();
    audio.play();
  }

  playNewResponseAudio(){
    let audio = new Audio();
    audio.src = "../../../assets/newResponse.mp3";
    audio.load();
    audio.play();
  }

  onListScroll() {

    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  openProfile(){
    this.profileOpen = !this.profileOpen;

  }

  openNotification(){
    this.notificationOpen = !this.notificationOpen;
    this.api.updateNotificationAll({isRead:true},{userId:this.auth.currentUserValue.id}).subscribe(data=>{
      
      this.unreadNotifications = 0;
    })
  }

  clearNotification(){
    this.api.deleteNotificationAll({userId:this.auth.currentUserValue.id}).subscribe(data=>{
      
      this.notifications = [];
      
    })
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  addEmoji(event:any){
    this.typedMessage = this.typedMessage+event.emoji.native;
  }

  clearChat(){
    this.receiverId = "";
    this.convId = "";
    this.isFlag = false;
    this.username = "";
    this.chatMessagesData = [];
    this.isStatus = "";
    this.isOpen = false;
    this.isProfile = "";
  }

  getData() {
    if (this.auth.currentUserValue) {
      this.api.getProject({ user: this.auth.currentUserValue.id }, this.page, this.limit, this.searchValue).subscribe(data => {
        this.dataS = data.data;
        

      })
    }
  }

  updateProfile(){
    if(!this.userName || !this.userEmail){
      this.updateUserError = true;
      return;
    }

    let data = JSON.stringify({
      data:{
        name:this.userName,
        email:this.userEmail,
        profession:this.userProfession,
        address: this.userAddress,
        city: this.userCity,
        state: this.userState
      }

    })

    this.api.updateUser(data,this.auth.currentUserValue.id).subscribe(res=>{
      this.auth.currentUserValue.profession = this.userProfession;
      this.auth.currentUserValue.address = this.userAddress;
      this.auth.currentUserValue.city = this.userCity;
      this.auth.currentUserValue.state = this.userState;
      this.toastr.success("Updated Successfully");
      this.updateProfileOpen = false;
      

    },error => {

    })
  }

  createNewServiceRequest(){
    this.isLoading = true;
    const data = JSON.stringify({
      message : this.message,
      name: this.userName,
      email: this.userEmail,
      mobile: this.userMobile
    });
    this.api.createNewServiceAdd(data).subscribe(res=>{
      if(res.status){
        this.isLoading = false;
        this.isModalOpen = !this.isModalOpen;
        this.getData();
        this.message = "";
        this.toastr.success("Created Successfully");
        this.closeaddserviceRequestModal();

      }else{
        this.isLoading = false;
        this.toastr.error(res.message);
      }
    },error => {
      this.isLoading = false;
      this.toastr.error(error.message);
    })
  }

  createNewCalculatorComplain(){
    this.isLoading = true;
    const data = JSON.stringify({
      message : this.message,
      name: this.userName,
      email: this.userEmail,
      mobile: this.userMobile
    });
    this.api.createCalculatorComplain(data).subscribe(res=>{
      if(res.status){
        this.isLoading = false;
        this.isModalOpen = !this.isModalOpen;
        this.getData();
        this.message = "";
        this.toastr.success("Created Successfully");
        this.closeaddcalculatorComplainModal();

      }else{
        this.isLoading = false;
        this.toastr.error(res.message);
      }
    },error => {
      this.isLoading = false;
      this.toastr.error(error.message);
    })
  }

  create(){
    this.isLoading = true;
    if(!this.edit){
      const data = JSON.stringify({
        title : this.newProject,
        user:this.auth.currentUserValue.id
      });
      
      this.api.createProject(data).subscribe(res=>{
        if(res.status){
          this.isLoading = false;
          this.isModalOpen = !this.isModalOpen;
          this.getData();
          this.title = "";
          this.toastr.success("Created Successfully");
          this.projectLink()

        }else{
          this.isLoading = false;
          this.toastr.error(res.message);
        }
      },error => {
        this.isLoading = false;
        this.toastr.error(error.message);
      })
    }else{
      const data = JSON.stringify({
        title : this.title
      });
      this.api.updateProject(data,this.item._id).subscribe(res=>{
        if(res.status){
          this.isLoading = false;
          this.isModalOpen = !this.isModalOpen;
          this.item.title = this.title;
          this.getData();
          this.title = "";
          this.toastr.success("Updated Successfully");
        }else{
          this.isLoading = false;
          this.toastr.error(res.message);
        }
      },error => {
        this.isLoading = false;
        this.toastr.error(error.message);
      })
    }

  }


  openServiceRequestModal(serviceRequest:any){
    this.newServiceRequest = serviceRequest;
    this.serviceRequestModal = true;
  }

  closeServiceRequestModal(){
    this.newServiceRequest = "";
    this.serviceRequestModal = false;
  }

  stopServiceRequestModa(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }

  stopProfileOpenModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }

  stopNotificationOpenModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }

  stopUpdateProfileOpenModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }

  openCalculatorModal(){
    this.calculatorModal.show();
  }

  closeCalculatorModal(){
    this.calculatorModal.hide();
  }

  openQuicklinksmodal(){
    this.quicklinksmodal.show();
  }

  closeQuicklinksmodal(){
    this.quicklinksmodal.hide();
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  openpartnerappModal(){
    this.partnerappModal.show();
  }

  closepartnerappModal(){
    this.partnerappModal.hide();
  }

  openprofilePhotoModal(){
    this.profilePhotoModal.show();
  }

  closeprofilePhotoModal(){
    this.profilePhotoModal.hide();
  }


  openaddprojectModal(){
    this.addprojectModal.show();
  }

  closeaddprojectModal(){
    this.addprojectModal.hide();
  }

  openaddserviceRequestModal(){
    this.addserviceRequestModal.show();
  }

  closeaddserviceRequestModal(){
    this.addserviceRequestModal.hide();
  }

  openaddcalculatorComplainModal(){
    this.addcalculatorComplainModal.show();
  }

  closeaddcalculatorComplainModal(){
    this.addcalculatorComplainModal.hide();
  }

  projectLink() {
    this.api.getProject({}, 1, 1000, "").subscribe((cdata: any) => {
      this.getProjects = cdata.data;
      
      this.router.navigate(['project', this.getProjects[0]._id]);
    });
  }

  inputFileClick() {
    this.document.getElementById('choosefile')?.click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.capturePhotoS = false;
    this.profileOpen = false;
    this.updateProfileOpen = false;
    this.openprofilePhotoModal();
  }


  imageCropped(event: any) {
    var reader = new window.FileReader();
    reader.readAsDataURL(event.blob);
    reader.onloadend = () => {
      this.croppedImage = reader.result;
      this.croppedImageFile = base64ToFile(this.croppedImage);
    }
  }

  search(event:any){
    if(event.target.value){
      this.chatData = this.chatData.filter((obj:any) => {
        let name = obj.name.toLowerCase();
        return name.includes(event.target.value) || name.includes(event.target.value);
      });
    }else{
      this.chatData = [];
      this.fetchConversation();
    }
   
  }


  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      
  }

  loadImageFailed() {
      
  }

  uploadDoc(): void {
    let fileData: FormData = new FormData();
    fileData.append('file', this.croppedImageFile);
    this.api.uploadFile(fileData).subscribe(res => {
      const data =JSON.stringify({
        data:{
          "photo":res.data.url
        }
      })
      this.api.updateUser(data,this.user).subscribe(data=>{
        this.toastr.success("Profile Photo Updated Successfully");
        this.profilePhoto = res.data.url;
        let localData = {
          id: this.auth.currentUserValue.id,
          photo: res.data.url,
          email: this.auth.currentUserValue.email,
          role: this.auth.currentUserValue.role,
          accessToken: this.auth.currentUserValue.accessToken,
          refreshToken: this.auth.currentUserValue.refreshToken,
          name: this.auth.currentUserValue.name,
          lastLoginOn: this.auth.currentUserValue.lastLoginOn,
          mobile: this.auth.currentUserValue.mobile,
          address: this.auth.currentUserValue.address,
          city: this.auth.currentUserValue.city,
          state: this.auth.currentUserValue.state,
          profession: this.auth.currentUserValue.profession,
          walletBalance: this.auth.currentUserValue.walletBalance
        }
        localStorage.setItem('currentUser', JSON.stringify(localData));
        this.auth.updateData(localData);
        this.closeprofilePhotoModal();
      },error => {
        this.toastr.error(error.message);
      });
    }, error => {
      
      this.toastr.error(error.message);
    });
    
  }
}
