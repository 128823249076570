import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(public http: HttpClient,@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private document: Document) {
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")!));
      this.currentUser = this.currentUserSubject.asObservable();  
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(null!);
      this.currentUser = this.currentUserSubject.asObservable();
    }
    
    
    
    
    
    
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  updateData(data:any){
    this.currentUserSubject.next(data);
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null!);
    window.location.replace("/");
  }
}
