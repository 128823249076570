<header class="md:fixed top-0 left-0 right-0 py-5 border-b-[#cbcccc] border bg-white z-[1]">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <nav class="relative z-50 flex justify-between">
      <div class="flex items-center md:gap-x-12">
        <a aria-label="Home" href="/#">
          <img src="assets/logo.png" alt="Foot2Feet Logo" class="h-10 w-auto" width="158px" height="40px" />
        </a>
      </div>
      <div class="flex items-center gap-x-5 md:gap-x-4">
        <div class="hidden md:flex md:gap-x-6">
          <!-- <a class="inline-block self-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            [routerLink]="['/']" routerLinkActive="text-primary">Home</a> -->
            <div class="group relative dropdown inline-flex">
              <span
                class="self-center rounded-lg px-2 py-1 text-sm cursor-pointer text-slate-700 hover:bg-slate-100 hover:text-slate-900">Add
                My Projects</span>
              <ul
                class="absolute group-hover:block hidden dropdown-menu  top-[20px] z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
  
                <li>
                  <a *ngFor="let item of dataS.projects" [routerLink]="['/project', item._id]"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem">
                    <div class="inline-flex items-center">
                      {{ item.title }}
                    </div>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/my-dashboard']"
                    class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem">
                    <div class="inline-flex items-center">
                      All Projects
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          <p class="cursor-pointer inline-block self-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            (click)="openServiceRequestModal('');">Add Services</p>
          <div class="group relative dropdown inline-flex">
            <span
              class="self-center rounded-lg px-2 py-1 text-sm cursor-pointer text-slate-700 hover:bg-slate-100 hover:text-slate-900">Add
              Calculators</span>
            <ul
              class="absolute group-hover:block hidden dropdown-menu  top-[20px] z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" [routerLink]="['/fsi-calculator']">FSI
              </li>
              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                [routerLink]="['/side-margin-calculator']">Side Margin</li>
              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">Developement Charges</li>
              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">Water Requirement</li>
              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">TDR</li>
              <li class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">Parking</li>
            </ul>
          </div>
          <a class="cursor-pointer inline-block self-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            href="https://redevelopment.foot2feet.com" target="_blank">Redevelopment</a>
          <a class="inline-block self-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            [routerLink]="['/questions']" routerLinkActive="text-primary">Questions</a>
            <a class="inline-block self-center rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            [routerLink]="['/blog']" routerLinkActive="text-primary">Blogs</a>
            @if(auth.currentUserValue){
            <a (click)="openNotification()" class="relative cursor-pointer inline-block self-center rounded-lg px-2 py-1 text-base text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            ><i class="fa-regular fa-bell"></i>
            <div *ngIf="unreadNotifications != 0" class="absolute inline-flex items-center justify-center w-4 h-4 p-2 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900" style="font-size: 0.75rem !important;">{{unreadNotifications}}</div></a>
            }
        </div>

        @if(auth.currentUserValue){
        <div class="flex items-center md:order-2 space-x-1 md:space-x-0 rtl:space-x-reverse">
          <button type="button" data-dropdown-toggle="language-dropdown-menu"
            class="inline-flex items-center font-medium justify-center px-4 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
            <img class="w-9 h-9 rounded-full hidden md:inline-block " src="{{baseURL}}/file/retrieve/{{profilePhoto}}"
              onerror="this.src='assets/images/user.webp'" alt="user photo">
            <span class="ps-2 whitespace-nowrap hidden md:block self-center text-sm text-slate-700"
              style="text-transform: capitalize;text-align: left;"><b>{{ auth.currentUserValue.name }}</b><br />+91
              {{ auth.currentUserValue.mobile }}</span>
          </button>
          <!-- Dropdown -->
          <div
            class="z-50 hidden my-4  text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
            id="language-dropdown-menu">
            <ul class="py-2 px-2 w-44 font-medium" role="none">
              <li>
                <a class="block px-4 py-2 text-xs text-gray-500" role="menuitem">
                  <div class="inline-flex items-center">
                    Dashboard
                  </div>
                </a>
              </li>
              <li>
                <a *ngFor="let item of dataS.projects" [routerLink]="['/project', item._id]"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem">
                  <div class="inline-flex items-center">
                    {{ item.title }}
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="['/my-dashboard']"
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem">
                  <div class="inline-flex items-center">
                    All Projects
                  </div>
                </a>
              </li>
              <hr class="block" />

              <li>
                <a (click)="openProfile()"
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem">
                  <div class="inline-flex items-center">
                    Profile
                  </div>
                </a>
              </li>
              <li>
                <a (click)="auth.logout()"
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem">
                  <div class="inline-flex items-center">
                    Sign out
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        } @else {
        <a class=" items-center justify-center rounded-full py-2 px-4 text-sm font-semibold bg-primary hover:bg-primary text-white"
          [routerLink]="['/sign-in']" routerLinkActive="router-link-active">
          <span>Get Started</span>
        </a>
        }


        @if(auth.currentUserValue){
          <a (click)="openNotification()" class="md:hidden inline-block self-center rounded-lg px-2 py-1 text-lg text-slate-700 hover:bg-slate-100 hover:text-slate-900" ><i class="fa-regular fa-bell"></i></a>
          }
        <div class="-mr-1 md:hidden">
          <div>
            <button (click)="isSidemenu = !isSidemenu"
              class="relative z-10 flex h-8 w-8 items-center justify-center [&amp;:not(:focus-visible)]:focus:outline-none"
              aria-label="Toggle Navigation" type="button" aria-expanded="false" id="headlessui-popover-button-:R3p6:">
              <svg aria-hidden="true" *ngIf="!isSidemenu" class="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                fill="none" stroke-width="2" stroke-linecap="round">
                <path d="M0 1H14M0 7H14M0 13H14" class="origin-center transition"></path>
                <path d="M2 2L12 12M12 2L2 12" class="origin-center transition scale-90 opacity-0"></path>
              </svg>
              <svg class="h-6 w-6" *ngIf="isSidemenu" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
<main class="min-h-screen md:mt-20">
  <router-outlet></router-outlet>
</main>
<footer class="bg-primary ">
  <div class="px-6 py-6 border-b border-b-slate-300 container">
    <div class="flex items-center gap-x-5 md:gap-x-8">
      <div class="md:flex md:gap-x-2">
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/']" routerLinkActive="router-link-active">Home</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/all-services']" routerLinkActive="router-link-active">All Services</a>
        <p class="inline-block rounded-lg  cursor-pointer px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          (click)="openCalculatorModal()" routerLinkActive="router-link-active">All Calculators</p>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/company/about-us']" routerLinkActive="router-link-active">About Us</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/company/contact-us']" routerLinkActive="router-link-active">Contact Us</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/company/terms-and-conditions']" routerLinkActive="router-link-active">Terms & Conditions</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/company/privacy-policy']" routerLinkActive="router-link-active">Privacy Policy</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/blog']" routerLinkActive="router-link-active">Blog</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          href="/sitemap.xml" target="_blank">Sitemap</a>
        <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:border hover:border-slate-100 hover:text-white"
          [routerLink]="['/questions']" routerLinkActive="router-link-active">Questions</a>
      
      </div>
    </div>
  </div>
  <div class="px-6 py-6 container">
    <div class="flex items-center gap-x-5 md:gap-x-8">
      <div class="md:flex md:gap-x-2">
        <p class="inline-block rounded-lg px-2 py-1 text-sm text-white ">
          Copyright © 2023 Foot2Feet | Foot2Feet is registered TradeMark of
          "Foot2Foot Pvt Ltd"
        </p>
      </div>
    </div>
  </div>
</footer>

<!-- Chat List -->
<div *ngIf="this.auth.currentUserValue"
  class="fixed messaging border w-full sm:w-2/5 md:w-[30%] lg:w-1/4 xl:w-1/5 z-10 ml-2 border-slate-300 bottom-0 rounded-ss-lg rounded-se-lg right-0 bg-white shadow-lg shadow-gray-300">
  <div class="px-4 py-2 justify-between items-center flex border-b cursor-pointer" (click)="isMessaging = !isMessaging">
    <img src="assets/images/chat.webp" class="rounded-full h-[30px] me-3 flex-shrink-0" />
    <div class="overflow-hidden text-ellipsis whitespace-nowrap flex-1">
      <h3 class="font-medium text-sm leading-5 text-ellipsis whitespace-nowrap overflow-hidden">
        Messaging
      </h3>
    </div>
    <a class="hover:bg-slate-200 px-1 rounded-full text-center">
      <i class="fa-solid fa-chevron-up " *ngIf="!isMessaging"></i>
      <i class="fa-solid fa-chevron-down " *ngIf="isMessaging"></i>
    </a>
  </div>
  <div class="bg-white w-full p-2" *ngIf="isMessaging">
    <div class="bg-gray-100 mx-auto my-3 max-w-2xl rounded ring-1 ring-gray-200 sm:mt-1 lg:mx-0 lg:flex lg:max-w-none">
      <div class="lg:m-3 block w-full">
        <div class="relative rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm"><i class="fa-solid fa-magnifying-glass"></i></span>
          </div>
          <input type="text" (keyup)="search($event)" name="price" focuse="true" id="price"
            class="block w-full rounded-md py-1 pl-8 pr-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="h-[50vh] overflow-auto">
      <h4 class="mb-2 text-[11px] text-gray-500 font-bold uppercase">
        Conversations
      </h4>
      <section *ngIf="chatData.length == 0">
        <div class="text-lg text-slate-500 font-semibold font-mono text-center">No Messages Yet</div>
        <div class="text-center">
          <img class="items-center m-auto " width="150px" height="150px" src="assets/icons/waiting.gif"
            alt="not-found">
        </div>
        <div class=" text-slate-500 font-semibold font-mono text-center">
          <a class="">Messages will appear here after you post service</a>
        </div>
      </section>
      <div class="mb-2"
        *ngFor="let data of chatData; let i = index" [class]="{ active: data.userId == receiverId }" >
        <div class=" items-center flex relative border p-2 rounded-br-none rounded-md cursor-pointer" (click)="
        chatUsername(
          data.name,
          data.photo,
          data.status,
          data.conversationId,
          data.userId,
          i,
          data.service,
          data.serviceStatus
        )
        ">
          <div class="flex-shrink-0 chat-user-img  {{
              data.status
            }} relative  self-center">
            <div class="w-10 h-10">
              <img src="{{ baseURL }}/file/retrieve/{{ data.photo }}" onerror="this.src='assets/images/user.webp'"
                class="rounded-full" />
            </div>
            <span class="user-status" *ngIf="data.status == 'Online'"></span>
          </div>
          <div class="flex-1 min-w-0 ms-3">
            <p class="font-medium text-sm" style="text-transform: capitalize;">{{ data.name }}
              <span class="font-normal text-xs float-right">{{data.lastMessageDate |dateFilter }}</span></p>
            <p class="font-normal text-xs truncate-one-lines" >{{ data.lastMessage}}</p>
           
          </div>
          <div class="flex-shrink-0 chat-user-img  relative  self-center">
            <span *ngIf="data.newMessage && data.lastMessageBy != auth.currentUserValue.id" class="inline-flex items-center justify-center w-5 h-5 ms-2 text-sm font-semibold text-white bg-blue-500 rounded-full">
              {{data.newMessageCount}}
            </span>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div></div>
          <button [routerLink]="['/project',data?.service?.project]" [queryParams]="{service: data.service?._id}" class="truncate-one-lines bg-primary cursor-pointer text-white text-center text-xs py-1 rounded-b-md">
            {{ data?.service?.displayServiceText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- User Chat Section -->
<div *ngIf="isFlag"
  class="fixed z-50 border w-full sm:w-2/4 md:w-2/5 lg:w-[30%] xl:w-1/4 sm:block md:block lg:block xl:block border-slate-300 bottom-0 rounded-ss-lg rounded-se-lg sm:right-[43%] md:right-[33%] lg:right-[27%] xl:right-[22%] bg-white shadow-lg shadow-gray-300">
  <div class="px-4 py-2 justify-between items-center flex border-b cursor-pointer" >
    <div (click)="isOpen = !isOpen" class="flex-shrink-0 me-3 chat-user-img  {{
        isStatus
      }} relative  self-center">
      <div class="w-8 h-8">
        <img src="{{ isProfile }}" onerror="this.src='assets/images/user.webp'" class="rounded-full" />
      </div>
      <span class="user-status" *ngIf="isStatus == 'Online'"></span>
    </div>
    <div (click)="isOpen = !isOpen" class="overflow-hidden text-ellipsis justify-between whitespace-nowrap flex-1">
      <h3 class="font-medium text-sm leading-4 text-ellipsis whitespace-nowrap overflow-hidden capitalize me-2">
        {{ username }}<br>
        <span class="text-xs text-slate-600 text-ellipsis whitespace-nowrap overflow-hidden">{{serviceName}}</span>
        <span *ngIf="chatService" class="text-[10px] font-semibold inline-block text-primary float-right">
          {{chatService.projectCompletionRate}}% Completed
        </span>
      </h3>
      <div *ngIf="chatService" class="overflow-hidden h-1.5 mt-1 text-xs flex rounded bg-blue-50 border border-gray-400 border-[0.5px]">
        <div [ngStyle]="{'width':chatService.projectCompletionRate+'%'}"
          class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-blue-700 "></div>
      </div>
    </div>
    <a class="hover:bg-slate-200 px-1 me-2 rounded-full text-center" href="tel:+{{mobile}}">
      <i class="fa-solid fa-phone"></i>
    </a>
    <a href="https://wa.me/+{{mobile}}?text=Hello," target="_blank"
          class=" hover:bg-slate-200 px-1 me-2 rounded-full text-center"><img
            height="20px" width="20px" class="m-auto mb-1" src="assets/images/whatsapp.png" alt="Whatsapp" /></a>
    <a class="hover:bg-slate-200 px-1 rounded-full text-center" (click)="clearChat()">
      <i class="fa-solid fa-xmark"></i>
    </a>
  </div>
  <div class="bg-white w-full p-2" *ngIf="isOpen">
    <p id="loadMoreBtn" (click)="loadMore()" *ngIf="mlimit <= chatMessagesData.length" class="text-center text-[#999999] font-semibold m-1">Load More</p>
    <div class="h-[78vh] md:h-[54vh] sm:h:[50vh] overflow-auto" #scrollMe>
      <ul class="list-none pt-3 mb-0" id="users-conversation">
        <li class="flex m-0 mb-1" *ngFor="let data of chatMessagesData"
          [class]="data.align === 'right' ? 'justify-end' : ''">
          <div class="text-left mb-0 inline-flex relative items-end max-w-[80%]"
            [class]="data.align === 'right' ? 'text-right' : ''">
            <div class="user-chat-content">
              <div class="ctext-wrap flex mb-1" [class]="data.align === 'right' ? 'justify-end' : ''">
                <div class="ctext-wrap-content px-3 py-1 relative rounded-sm text-left" [class]="
                    data.align === 'right'
                      ? 'order-2 bg-blue-100 shadow-none'
                      : 'bg-gray-100 shadow-sm'
                  ">
                  <p class="mb-0 ctext-content text-sm">{{ data.message }}</p>
                  <p class="text-gray-400 text-xs time text-right" [class]="data.align === 'right' ? 'order-2' : ''">
                    {{ data.createdAt | dateFilter }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="">
      <div class="flex w-full gap-2">
        <!-- <div class="flex-none">
          <a class="cursor-pointer" (click)="isEmojy = !isEmojy">
            <i class="fa-regular fa-face-smile text-gray-400 text-2xl"></i>
          </a>
          <emoji-mart *ngIf="isEmojy" (emojiClick)="addEmoji($event)" [sheetSize]="64" [showPreview]="false"
            [enableFrequentEmojiSort]="true" [style]="{ position: 'absolute', bottom: '50px' }" emoji="point_up">
          </emoji-mart>
        </div> -->
        <div class="grow">
          <input [(ngModel)]="typedMessage" (keydown)="onEnterKey($event)"
            class="block w-full border rounded-md py-1 px-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            placeholder="Type your message..." />
        </div>
        <div class="flex-none">
          <button (click)="messageSave()" [disabled]="msgLoading"
            class="flex-initial px-2 h-full disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
            <img src="assets/images/send.webp" class="h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Side Menu -->
<div *ngIf="isSidemenu" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50" (click)="isSidemenu = false">
    <div (click)="stopSidemenuModal($event)"
      class="min-h-full w-[min(20rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">

      <nav class="divide-y divide-slate-900/10 text-base leading-7 text-slate-900">
        <div class="flex items-center h-20 bg-primary" style="border-bottom: 1px solid  #ccc;">

          <div class="m-1"><img src="{{baseURL}}/file/retrieve/{{profilePhoto}}"
              onerror="this.src='assets/images/user.webp'" alt="" class="w-10 m-1"></div>
          <div class="flex-1 text-white text-lg font-medium" *ngIf="auth.currentUserValue">
            <span
              class="ps-2 whitespace-nowrap block self-center">{{ auth.currentUserValue.name }}<br />+91{{ auth.currentUserValue.mobile }}</span>
          </div>
          <div class="flex-1 text-lg font-semibold" *ngIf="!auth.currentUserValue">
            <a class="block text-white p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/sign-in']"
              routerLinkActive="text-black">Login</a>
          </div>
          <div class="m-3 text-white text-lg cursor-pointer" (click)="isSidemenu = !isSidemenu"><i
              class="fa-solid fa-close"></i>
          </div>
        </div>

        <!-- Dashboard -->
        <div *ngIf="this.auth.currentUserValue">
          <div class="items-start">
            <a class="block px-2 py-2 text-#ccc-500 text-lg bg-[#eee]">
              <h3 class="text-black  w-auto font-semibold p-1">Dashboard</h3>
            </a>
          </div>

          <ul style="margin-top: 0px !important;" class="mt-0 rounded-md bg-white py-1">
            <div class="flex" style="border-bottom: 1px solid  #ccc; " *ngFor="let item of dataS.projects">
              <div class="m-1"><img src="assets/images/check-mark.png" alt="" class="w-8 m-1"></div>
              <div class="flex-1 text-md">
                <a class="block    px-2 py-2 text-md" (click)="isSidemenu = !isSidemenu" style=" margin-top: 2%;"
                  [routerLink]="['/project',item._id]">{{item.title}}</a>
              </div>
              <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
            </div>
            <div class="flex " style="border-bottom: 1px solid  #ccc; ">
              <div class=""><img src="assets/images/plusi.png" alt="" class="w-12"></div>
              <div class="flex-1 text-md">
                <a class="block   p-3" (click)="openaddprojectModal(); isSidemenu = !isSidemenu"
                  routerLinkActive="text-black">Create Project</a>
              </div>
              <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
            </div>

            <div class="flex " style="border-bottom: 1px solid  #ccc; ">
              <div class="m-1"><img src="assets/images/check.png" alt="" class="w-8 m-1"></div>
              <div class="flex-1 text-md">
                <a class="block   p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/my-dashboard']"
                  routerLinkActive="text-black">All Projects</a>
              </div>
              <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
            </div>
            <div class="flex " style="border-bottom: 1px solid  #ccc; " *ngIf="auth.currentUserValue">
              <div class="m-1"><img src="assets/images/profile.png" alt="" class="w-8 m-1"></div>
              <div class="flex-1 text-md">
                <a class="block   p-3" (click)="isSidemenu = !isSidemenu" (click)="openProfile()"
                  routerLinkActive="text-black">Profile</a>
              </div>
              <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
            </div>
          </ul>
        </div>
        <!-- Dashboard End -->
        <!-- Explore -->

        <div class="items-start">
          <a class="block px-2 py-2 text-#ccc-500 text-lg bg-[#eee]">
            <h3 class="text-black  w-auto font-semibold p-1">Explore</h3>
          </a>
        </div>
        <ul style="margin-top: 0px !important;" class="mt-0 rounded-md bg-white py-1 px-2">
          <div class="flex " style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/dashboard.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/']"
                routerLinkActive="text-black">Home</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>
          <div class="flex  text-black" style="border-bottom: 1px solid  #ccc;">
            <div class="m-1"><img src="assets/images/add-service.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="isSidemenu = !isSidemenu" (click)="openServiceRequestModal('')"
                routerLinkActive="text-black">Add
                Services</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex  text-black" style="border-bottom: 1px solid  #ccc;">
            <div class="m-1"><img src="assets/images/question.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/questions']"
                routerLinkActive="text-black">Question</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/sharenew.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="openQuicklinksmodal();isSidemenu = !isSidemenu"
                routerLinkActive="text-black">Useful
                Link</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/D1.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="calOpen = !calOpen"
                href="https://redevelopment.foot2feet.com" target="_blank"
                routerLinkActive="text-black">Redevelopment</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>
          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/blogging.png" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/blog']"
              routerLinkActive="text-black">Blogs</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>
        </ul>
        <!-- Explore End -->

        <!-- Calculator -->
        <div class="items-start">
          <a class="block px-2 py-2 text-#ccc-500 text-lg bg-[#eee]">
            <h3 class="text-black  w-auto font-semibold p-1">Calculator</h3>
          </a>
        </div>
        <ul style="margin-top: 0px !important;" class="mt-0 rounded-md bg-white py-1 px-2">
          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/FSI_Calculator.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="isSidemenu = !isSidemenu"
                [routerLink]="['/fsi-calculator']" routerLinkActive="text-black">FSI</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>

          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/side_margin_calculator.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="isSidemenu = !isSidemenu"
                [routerLink]="['/side-margin-calculator']" routerLinkActive="text-black">Side Margin</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>

          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/charges.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="calOpen = !calOpen"
                [routerLink]="['/side-margin-calculator']" routerLinkActive="text-black">Developement Charges</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>

          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/water.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="calOpen = !calOpen"
                routerLinkActive="text-black">Water Requirement</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>

          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/tdr.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="calOpen = !calOpen"
                routerLinkActive="text-black">TDR</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>

          <li class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/parking.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="calOpen = !calOpen"
                routerLinkActive="text-black">Parking</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </li>
        </ul>

        <div class="items-start">
          <a class="block px-2 py-2 text-#ccc-500 text-lg bg-[#eee]">
            <h3 class="text-black  w-auto font-semibold p-1">Company</h3>
          </a>
        </div>

        <ul style="margin-top: 0px !important;" class="mt-0 rounded-md bg-white py-1 px-2">
          <div class="flex  text-black" style="border-bottom: 1px solid  #ccc;">
            <div class="m-1"><img src="assets/images/about.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="isSidemenu = !isSidemenu"
                [routerLink]="['/company/about-us']" routerLinkActive="text-black">About Us</a></div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/partnernew.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="openpartnerappModal();isSidemenu = !isSidemenu"
                routerLinkActive="text-black">Join As Patner</a></div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/sharenew.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="calOpen = !calOpen"
                routerLinkActive="text-black">Share</a></div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex" style="border-bottom: 1px solid  #ccc; ">
            <div class="m-1"><img src="assets/images/contact.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md">
              <a class="block   p-3" (click)="isSidemenu = !isSidemenu" [routerLink]="['/company/contact-us']"
                routerLinkActive="text-black">Contact Us</a>
            </div>
            <div class="m-3  text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>

          <div class="flex " style="border-bottom: 1px solid  #ccc; " *ngIf="auth.currentUserValue">
            <div class="m-1"><img src="assets/images/logout.svg" alt="" class="w-8 m-1"></div>
            <div class="flex-1 text-md"> <a class="block   p-3" (click)="auth.logout();isSidemenu = !isSidemenu"
                routerLinkActive="text-black">Sign Out</a></div>
            <div class="m-3 font-bold text-lg"><i class="fa-solid fa-chevron-right"></i></div>
          </div>
        </ul>
      </nav>
    </div>
</div>

<!-- Add Project Modal -->
<div id="profilePhotoModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Update Profile Photo
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeprofilePhotoModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <form class="p-4 md:p-5 max-h-[40%] overflow-auto">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1 / 1" [resizeToWidth]="256"
          [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="true" [canvasRotation]="canvasRotation"
          [transform]="transform" [alignImage]="'center'" [style.display]="showCropper ? null : 'none'" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
        <div class="sm:flex sm:flex-row-reverse mt-2">
          <button type="button"
            class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="uploadDoc();closeprofilePhotoModal()">Update</button>
          <button type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="closeprofilePhotoModal()">Cancel</button>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- Side Menu -->
<div *ngIf="notificationOpen" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50" (click)="notificationOpen = false">
  <div (click)="stopNotificationOpenModal($event)"
    class="min-h-full w-[min(20rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">
    <nav class="divide-y divide-slate-900/10 text-base leading-7 text-slate-900">
      <div class="flex items-center bg-primary" style="border-bottom: 1px solid  #ccc;">
        <div class="flex-1 text-white text-lg font-medium">
          <span class="ps-2 whitespace-nowrap block self-center">Notifications</span>
        </div>
        <div class="m-3 text-white text-lg cursor-pointer" (click)="notificationOpen = !notificationOpen;"><i
            class="fa-solid fa-close"></i></div>
      </div>
      <div class="p-2 px-0">
        <div
        class="bg-white mx-auto my-3 text-center rounded sm:mt-1 lg:mx-0 lg:flex"
        *ngIf="notifications.length == 0">
        <span>No Notifications</span>
        </div>
        @if(notifications.length > 0){
          <div
          class="bg-white mx-auto my-3 border-b rounded sm:mt-1 lg:mx-0 lg:flex"
          *ngFor="let item of notifications">

          <div class="p-1 px-2 sm:p-2 lg:flex-auto">
            <a [routerLink]="[item.path]" (click)="notificationOpen = false">
            <div class="my-2 mt-0">
              <h3 class="text-base font-medium leading-5 tracking-tight text-gray-700">{{item.title}}</h3>
              <h3 class="mt-0.5 text-sm leading-5 text-gray-600">{{item.message}}</h3>
            </div>

            <div class="flex pt-0.5">
              <div title="Question Asked On" class="text-slate-500 flex-1 text-right leading-4 text-xs mx-2">
                <span class="mx-2 text-slate-700 font-medium">{{item.createdAt| date:'dd MMM, yyyy hh:mm a'}}</span>
              </div>
            </div>

            </a>
          </div>

        </div>
        <div class="text-center text-sm my-2 text-red-500 font-semibold cursor-pointer"
      (click)="clearNotification();">CLEAR</div>
        }
        
      </div>
    </nav>
  </div>
</div>


<!-- Mobile Profile Menu -->
<div *ngIf="profileOpen" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50"  (click)="profileOpen = false">
    <div (click)="stopProfileOpenModal($event)" 
      class="min-h-full w-[min(20rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">
      <nav class="divide-y divide-slate-900/10 text-base leading-7 text-slate-900">
        <div class="flex items-center bg-primary" style="border-bottom: 1px solid  #ccc;">
          <div class="flex-1 text-white text-lg font-medium">
            <span class="ps-2 whitespace-nowrap block self-center">My Profile</span>
          </div>
          <div class="m-3 text-white text-lg cursor-pointer"
            (click)="profileOpen = !profileOpen;updateProfileOpen = !updateProfileOpen"><i class="fa-solid fa-edit"></i>
          </div>
          <div class="m-3 text-white text-lg cursor-pointer" (click)="profileOpen = !profileOpen;"><i
              class="fa-solid fa-close"></i></div>
        </div>
        <div class="px-4 py-6">
          <div>
            <div class="w-24 h-24 relative mx-auto">
              <img class=" mb-3 mx-auto rounded-full shadow-lg" src="{{baseURL}}/file/retrieve/{{profilePhoto}}"
                onerror="this.src='assets/images/user.webp'" />
              <input type="file" id="choosefile" class="hidden" (change)="fileChangeEvent($event)">
              <label for="choosefile" class="cursor-pointer">
                <div
                  class="absolute w-6 h-6 text-xs pt-[2px] text-center font-bold text-white bg-amber-500 border-2 border-white rounded-full bottom-0 end-0 dark:border-gray-900">
                  <i class="fa-solid fa-camera"></i>
                </div>
              </label>
            </div>

          </div>
          <p class="leading-6 text-[0.875rem]">Name</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed"
            style="text-transform: capitalize;">
            {{ (auth.currentUserValue.name) ? auth.currentUserValue.name : "-" }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">Email</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            {{ (auth.currentUserValue.email) ? auth.currentUserValue.email : "-" }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">Mobile</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            +91 {{ auth.currentUserValue.mobile }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">Profession</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            {{ ((auth.currentUserValue.profession) ? auth.currentUserValue.profession : "-" )}}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">Address</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            {{ (auth.currentUserValue.address) ? auth.currentUserValue.address : "-" }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">City</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            {{ (auth.currentUserValue.city) ? auth.currentUserValue.city : "-" }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">State</p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            {{ (auth.currentUserValue.state) ? auth.currentUserValue.state : "-" }}
          </h3>
          <button type="submit" (click)="profileOpen = !profileOpen;updateProfileOpen = !updateProfileOpen"
            class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-blue-500 ">Update
            My Profile</button>
        </div>
      </nav>
    </div>
</div>

<!-- Update Profile Menu -->
<div *ngIf="updateProfileOpen" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50"  (click)="updateProfileOpen = false">
    <div (click)="stopUpdateProfileOpenModal($event)"
      class="min-h-full w-full md:w-[min(20rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">
      <nav class="divide-y divide-slate-900/10 text-base leading-7 text-slate-900">
        <div class="flex items-center h-20 bg-primary" style="border-bottom: 1px solid  #ccc;">
          <div class="flex-1 text-white text-lg font-medium">
            <span class="ps-2 whitespace-nowrap block self-center">Update Profile</span>
          </div>

          <div class="m-3 text-white text-lg cursor-pointer" (click)="updateProfileOpen = !updateProfileOpen"><i
              class="fa-solid fa-close"></i>
          </div>
        </div>
        <div class="px-4 py-6">
          <div>
            <div class="w-24 h-24 relative mx-auto">
              <img class=" mb-3 mx-auto rounded-full shadow-lg" src="{{baseURL}}/file/retrieve/{{profilePhoto}}"
                onerror="this.src='assets/images/user.webp'" />
              <input type="file" id="choosefile" class="hidden" (change)="fileChangeEvent($event)">
              <label for="choosefile" class="cursor-pointer">
                <div
                  class="absolute w-6 h-6 text-xs pt-[2px] text-center font-bold text-white bg-amber-500 border-2 border-white rounded-full bottom-0 end-0 dark:border-gray-900">
                  <i class="fa-solid fa-camera"></i>
                </div>
              </label>
            </div>

          </div>
          <p class="leading-6 text-[0.875rem]">Name <span class="text-red-500">*</span></p>
          <div class="mt-2">
            <input id="name" name="text" [(ngModel)]="userName" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <p class="leading-6 text-[0.875rem] mt-2">Email <span class="text-red-500">*</span></p>
          <div class="mt-2">
            <input id="email" name="text" [(ngModel)]="userEmail" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <p class="leading-6 text-[0.875rem] mt-2">Mobile <span class="text-red-500">*</span></p>
          <h3 class="leading-7 tracking-wide font-semibold text-[1rem] pb-1 border-slate border-b border-dashed">
            +91 {{ userMobile }}
          </h3>
          <p class="leading-6 text-[0.875rem] mt-2">Profession</p>
          <div class="mt-2">
            <input id="profession" name="text" [(ngModel)]="userProfession" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <p class="leading-6 text-[0.875rem] mt-2">Address</p>
          <div class="mt-2">
            <input id="address" name="text" [(ngModel)]="userAddress" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <p class="leading-6 text-[0.875rem] mt-2">City</p>
          <div class="mt-2">
            <input id="city" name="text" [(ngModel)]="userCity" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <p class="leading-6 text-[0.875rem] mt-2">State</p>
          <div class="mt-2">
            <input id="state" name="text" [(ngModel)]="userState" type="email" required
              class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
          <div *ngIf="updateUserError"
            class="my-2 flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 border"
            role="alert">
            <div
              class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 20 20">
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
              <span class="sr-only">Warning icon</span>
            </div>
            <div class="ms-3 text-sm font-normal">Kindly fill mandatory fields</div>
            <button type="button" (click)="updateUserError = !updateUserError"
              class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              data-dismiss-target="#toast-warning" aria-label="Close">
              <span class="sr-only">Close</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
          <button type="button" (click)="updateProfile()"
            class=" mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-blue-500 ">Submit</button>
          <button type="button" (click)="updateProfileOpen = !updateProfileOpen"
            class=" mt-6 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border text-dark ">Cancel</button>
        </div>
      </nav>
    </div>
</div>

<!-- Calculator-modal -->
<div id="calculatorModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          All Calculators
        </h3>
        <button type="button" (click)="closeCalculatorModal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-4 md:p-5">
        <ul class="space-y-4 mb-4">
          <li>
            <input type="radio" id="job-1" name="job" value="job-1" class="hidden peer" required>
            <label for="job-1" [routerLink]="['/fsi-calculator']" (click)="closeCalculatorModal()"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">FSI</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
          <li>
            <input type="radio" id="job-2" name="job" value="job-2" class="hidden peer">
            <label for="job-2" [routerLink]="['/side-margin-calculator']" (click)="closeCalculatorModal()"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Side Margin</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
          <li>
            <input type="radio" id="job-3" name="job" value="job-3" class="hidden peer">
            <label for="job-3"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Developement Charges</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
          <li>
            <input type="radio" id="job-4" name="job" value="job-4" class="hidden peer">
            <label for="job-4"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Water Requirement</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
          <li>
            <input type="radio" id="job-5" name="job" value="job-5" class="hidden peer">
            <label for="job-5"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">TDR</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
          <li>
            <input type="radio" id="job-6" name="job" value="job-6" class="hidden peer">
            <label for="job-6"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Parking</div>
              </div>
              <svg class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Quick links Modal -->
<div id="quicklinksmodal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Quick Links
        </h3>
        <button type="button" (click)="closeQuicklinksmodal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-4 md:p-5">
        <ul class="space-y-4 mb-4">
          <li (click)="openLink(' https://bhulekh.mahabhumi.gov.in/')">
            <input type="radio" id="job-1" name="job" value="job-1" class="hidden peer" required>
            <label for="job-1"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Online 7/12</div>
              </div>
              <p><i class="fa-solid fa-arrow-up-right-from-square"></i></p>
            </label>
          </li>
          <li (click)="openLink('https://easr.igrmaharashtra.gov.in/')">
            <input type="radio" id="job-2" name="job" value="job-2" class="hidden peer">
            <label for="job-2"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Ready Reckoner Rates</div>
              </div>
              <a href=" https://easr.igrmaharashtra.gov.in/" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </label>
          </li>
          <li (click)="openLink('https://pmc.gov.in/en/development-plan-merged-23-villages-0')">
            <input type="radio" id="job-3" name="job" value="job-3" class="hidden peer">
            <label for="job-3"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Development Plans - PMC</div>
              </div>
              <a href="https://pmc.gov.in/en/development-plan-merged-23-villages-0" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </label>
          </li>
          <li (click)="openLink(' https://www.pcmcindia.gov.in/TP_info.php')">
            <input type="radio" id="job-4" name="job" value="job-4" class="hidden peer">
            <label for="job-4"
              class="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
              <div class="block">
                <div class="w-full text-base font-medium">Development Plans - PCMC</div>
              </div>
              <a href="https://www.pcmcindia.gov.in/TP_info.php" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



<!-- partner modal -->
<div id="partnerappModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-2xl max-h-full bg-white rounded-md">
    <!-- Modal content -->
    <div class="">
      <div
        class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Are you business owner or
          freelancer?</h5>
        <h5 class="mb-2 text-lg font-bold text-gray-900 dark:text-white">Grow your business
          with us</h5>
        <h5 class="mb-2 text-base font-bold text-gray-900 dark:text-white">Which service do you
          provide?</h5>
        <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400"> <select
            class="border border-slate-900 rounded p-2 ">
            <option selected disabled>Select Service</option>
            <option *ngFor="let item of servicesData">{{item.displayText}}</option>
          </select></p>
        <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
          <a href="#"
            class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <svg class="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple"
              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path fill="currentColor"
                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
              </path>
            </svg>
            <div class="text-left rtl:text-right">
              <div class="mb-1 text-xs">Download on the</div>
              <div class="-mt-1 font-sans text-sm font-semibold">Mac App Store</div>
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.xcelet.foot2feet.partner" target="_blank"
            class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <svg class="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play"
              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
              </path>
            </svg>
            <div class="text-left rtl:text-right">
              <div class="mb-1 text-xs">Get in on</div>
              <div class="-mt-1 font-sans text-sm font-semibold">Google Play</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add Project Modal -->
<div id="addprojectModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Create New Project
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeaddprojectModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <form class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-2">
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="text" name="name" id="name" [(ngModel)]="newProject"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type project name" required="">
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button type="button"
            class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="create();closeaddprojectModal()">Submit</button>
          <button type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="closeaddprojectModal()">Cancel</button>
        </div>

      </form>
    </div>
  </div>
</div>

<div id="addserviceRequestModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Generate Request
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeaddserviceRequestModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <form class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-1">
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="text" name="name" id="name" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type name" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input type="email" name="name" id="name" [(ngModel)]="userEmail" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type email" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile</label>
            <input type="number" name="name" id="name" [(ngModel)]="userMobile" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type mobile" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
            <textarea type="text" rows="6" name="name" id="name" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type message" required=""></textarea>
          </div>
        </div>
        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" style="padding-right: 0px !important;">
          <button type="button" [disabled]="isLoading"
            class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="createNewServiceRequest();closeaddserviceRequestModal()">Submit</button>
          <button type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="closeaddserviceRequestModal()">Cancel</button>
        </div>

      </form>
    </div>
  </div>
</div>

<div id="addcalculatorComplainModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Raise a Calculator Complaint
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeaddcalculatorComplainModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <form class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-1">
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="text" name="name" id="name" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type name" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input type="email" name="name" id="name" [(ngModel)]="userEmail" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type email" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile</label>
            <input type="number" name="name" id="name" [(ngModel)]="userMobile" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type mobile" required="">
          </div>
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
            <textarea type="text" rows="6" name="name" id="name" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type message" required=""></textarea>
          </div>
        </div>
        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" style="padding-right: 0px !important;">
          <button type="button" [disabled]="isLoading"
            class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="createNewCalculatorComplain();closeaddcalculatorComplainModal()">Submit</button>
          <button type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="closeaddcalculatorComplainModal()">Cancel</button>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- Service Request Modal -->
<!-- <div *ngIf="serviceRequestModal" (click)="closeServiceRequestModal()"
  class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div class="relative w-full max-w-2xl max-h-full bg-primary rounded-md" (click)="stopServiceRequestModa($event)">
    <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
    <!- Modal content -->
    <!-- <app-service-request [service]="newServiceRequest" [isModal]="true">
    </app-service-request>
  </div>
</div> --> 
<div *ngIf="serviceRequestModal" (click)="closeServiceRequestModal()" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div class="relative w-full max-w-2xl max-h-full bg-primary rounded-md" (click)="stopServiceRequestModa($event)">
    <!-- Modal content -->
    <app-service-request [service]="newServiceRequest" [isModal]="true"></app-service-request>

    <!-- Move button to the right -->
    <button (click)="closeServiceRequestModal()" type="button" class="absolute top-0 right-0 m-4 text-white bg-transparent hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
</div>

