const Services: any = [
  {
    "url": "architect",
    "displayText": "Architect",
    "id": "architect",
    "descriptionHTML": `<ol class="list-decimal">
    <li class="ms-5">
    <h3 class="font-medium">Know more about Architect in Pune</h3>
    
    <p class="text-sm">The human being, however, is not just a living creature that needs space. However,
    the emotional response is no less important. The way a room is dimensioned, divided,
    painted, lit, entered and furnished by Architect has great significance which makes
    impression. Hence An architect understand the relationships of the size of limbs of
    a healthy being and how much space a person occupies in various postures and in
    movement. Architects are&nbsp; professionals who are trained in the art and science
    of building design. In short architect plays a key role in the construction activity
    by not only creating overall look of building but also other structures.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Roles and responsibilities of Architect in Pune</h3>
<p class="text-sm">Usually people needs places like where to live, play, worship, meet, eat, work, and
    shop. For instance architects are responsible for designing these places. From the
    very initial discussion with the client through the final delivery of the completed
    structure they are involved in every phases of a construction project. Whereas an
    prime role of an architect is to create design for any kind of construction
    projects. For which it requires his specialist construction knowledge and extra
    ordinary high level drawing skills to design building that are functional, safe,
    sustainable and aesthetically pleasing.</p>
</li>  
<li class="ms-5">

<h3 class="font-medium">Types of Architect in Pune</h3>
<p class="text-sm">A first person to whom we choose to build our dream project is an Architect who gives
    an astonishing impression. There are many types of architect to choose from as per
    our rewuirement, here is major of them listed –</p>

<h4 class="font-medium text-sm">i.Residential Architect</h4>
<h4 class="font-medium text-sm">ii.Commercial Architect</h4>
<h4 class="font-medium text-sm">iii.Bungalow Specialist Architect</h4>
<h4 class="font-medium text-sm">iv.Liaisoning Architect</h4>
<h4 class="font-medium text-sm">v.Industrial Architect</h4>
<h4 class="font-medium text-sm">vi.Landscape Architect</h4>
<h4 class="font-medium text-sm">vii.Interior Architect</h4>

</li>

<li class="ms-5">
<h3 class="font-medium">Design Parameters involved in Architecture</h3>

<p class="text-sm">Architect are familiar with the dimensions of theappliances, clothing etc. which
    &nbsp;people encounter every day, inorder to be able to determine the appropriate
    sizes for containersand furniture. For instance must know how much space a person
    needsbetween furniture in the kitchen, dining room, libraries etc. inorder to
    undertake the necessary reaching and working amongthese fittings in comfort without
    squandering space. Certainly architect must know how furniture should be placed so
    that people can fulfil their tasks or relax in the home, office or workshop. And,
    finally,the architect need to know the minimum practicaldimensions of spaces in
    which people move around on a dailybasis, like trains, trams, vehicles etc. Above
    all typically very restricted minimum spaces give the architect fixed
    impressions,which are then used, even if unintentionally, to deliver dimensions of
    other spaces. Find pdf of architectural data here.<a
        href="https://www.pdfdrive.com/architects-data-e18947586.html">https://www.pdfdrive.com/architects-data-e18947586.html</a>
</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Cost required for Architectural design</h3>

<p class="text-sm">The cost involve in the Architectural design are depend wise. As Comprehensive
    Architectural Services in the conditions of Engagement including Site Development
    but Excluding Landscape Architecture, Interior Architecture, Graphic and Signage are
    approx 3% or more of total cost of construction project. In the same vein, some
    architect charge on lump sum basis. You can only buy as house plan for small scale
    construction.</p>
</li>
<li class="ms-5">

<h3 class="font-medium">How Do I choose an Architect in pune</h3>

<p class="text-sm">Selecting an architect is for your dream home is one of the important and prime task
    in process of constriction. Because if you mistakenly choose wrong Architect; the
    whole project will lead to nowhere. So, first ever thing you can do is Google “Top
    Architect in pune” and check whether they are providing what you are looking for.
    This process may seem difficult to some of you. You can get consultation and free
    quotation from foot2feet.com as well. Hiring an architect from local city is a
    toughest challenge in competition world. As Architect in pune would also know the
    building codes and zoning requirement. For any query. Get more information to know
    the step by step guide for any construction.<a
        href="https://foot2feet.com/blog/a-9-step-guide-for-construction-of-any-building/">https://foot2feet.com/blog/a-9-step-guide-for-construction-of-any-building/</a>
</p>
</li>
<li class="ms-5">
<h3 class="font-medium">FAQ about Architect in Pune</h3>
<p>
<ol class="list-[lower-roman] ps-4">
<li class="ms-5">
<h3 class="font-medium">How
much do architectural firms in Pune charge per square feet?</h3>
<p class="text-sm">
Architectural cost for normal unfurnished home can be constructed at a cost of 950 – to 1000 rs Per square feet. And a finished home around takes 1250 to 1350 Rs Per Square feet.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Do I need an architect or a structural engineer?</h3>
<p class="text-sm">
If you are doing structural work, then you will need a structural engineer, whereas the role of architect depends on the complexity of project. Structural engineer and architects both design building projects.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">How long does it take an architect to draw house plans?</h3>
<p class="text-sm">
The time require to draw house plan is completely depends on the size of project. Whereas total set of plan for typical 3 bedroom house takes upto 7 to 8 hours to complete.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">What is difference between an architect and designer?</h3>
<p class="text-sm">
The basic difference between Architect and interior designers are professionals who develops design plans. Architects design and deliver the complete building, In the same vein Designer always tries to create the desired feel, look and mood within the building.</p>
</li>
</ol>
</p>`,
    "metaDescription": "Find Best architect in Pune for your Project. Hassle free Work. Trusted Service. One point of Contact from start to End. Call + 91 82829242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Top 5 Architects in Pune - Architectural firms - 15% discount -Foot2Feet",
    "category": "Consultants",
    "smallIcon": "architect.png",
    "largeIcon": "architect.webp",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft."]
      },
      {
        "title": "What services you require?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Drawing only", "Liasoning Only", "Complete Architecture", "Turnkey", "Landscaping"]
      },
      {
        "title": "Would you consider online design service?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Yes", "No", "Need to talk to the designer", "May or May not"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }

    ]
  },
  {
    "url": "structural-consultant",
    "displayText": "Structural Consultant",
    "id": "structuralConsultant",
    "descriptionHTML": "",
    "largeIcon": "structuralConsultant.webp",
    "smallIcon": "structuralConsultant.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "Please specify number of floors?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 5", "5 to 10 floors", "Above 10 floors"]
      },
      {
        "title": "How much is the built-up area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 5000 sq.ft", "5000 – 10000 sq.ft", "10000 - 20000 sq.ft", "Above 20000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "advocate",
    "displayText": "Advocate",
    "id": "advocate",
    "descriptionHTML": "",
    "largeIcon": "advocate.webp",
    "smallIcon": "advocate.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What kind of service is required?",
        "type": "text",
        "other": false,
        "otherIndex": 0
      }
    ]
  },
  {
    "url": "mep-consultant",
    "displayText": "MEP Consultant",
    "id": "MEPConsultant",
    "descriptionHTML": "",
    "largeIcon": "mepConsultant.webp",
    "smallIcon": "mepConsultant.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "environment-consultant",
    "displayText": "Environment Consultant",
    "id": "environmentConsultant",
    "descriptionHTML": "",
    "largeIcon": "environmentConsultant.webp",
    "smallIcon": "environmentConsultant.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["20,000 – 30,000 sq.m.", "More than 20,000 sq.m.", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "landscape",
    "displayText": "Landscape",
    "id": "landscape",
    "descriptionHTML": ` <ol class="list-decimal">

    <h3 class="font-medium">Know more about Landscape Design Architect</h3>
    <p class="text-sm">Landscape design architect creates beautiful outdoor spaces. The term landscape design
    covers two apparently contradictory elements. Landscape is traditionally
    thought to refer to undisturbed natural landscapes, and design is evidently
    artificial. But we must recognize that untouched landscapes are almost
    absent from large parts of the world, or exist only as a temporarily
    abandoned terrain subject solely to sporadic attacks. Built and unbuilt land
    are today strongly related in a dialectic relationship. This has also
    generated a spatial way of thinking in landscape design, comparable with
    architectural or town planning design processes.Landscape design architect
    plan design public outdoor spaces, such as gardens, campuses, parks,
    commercial centers, waterfront development, resorts, Institutional and
    industrial.</p>

<li class="ms-5">
  <h3 class="font-medium">Design Rules</h3>

    <p class="text-sm">A very basic rule landscape architect need to be in follow is that the
    building should be linked with the site.&nbsp; There are regulations about
    the distance of walls and fences from boundaries are laid down in the Law on
    the Rights of Neighbours and the individual state building regulations. The
    normal situation is that every house owner has to fence the right-hand side
    of their boundary as seen from the road. The joint back is to be fenced
    communally, i.e. the costs of minimal fencing (wire mesh fence, height =
    1.25 m are to be shared. If a house owner has a sole duty of enclosure, then
    they must bear the cost of fencing alone and the fencing must stand on their
    own property. If the enclosure duty is shared, then the barrier must be
    centred on the boundary. There is a general duty of enclosure when it is
    usual in the location.Walls and retaining walls including enclosures do not
    require.</p>

</li>
<li class="ms-5">
  <h3 class="font-medium">Cost required for Landscape Design</h3>

<p class="text-sm">
    The cost involve in the Landscape design are extraordinary high. Landscape
    design architect may charge the cost for Landscape design on lump sum basis
    or at the 5% of total cost for comprehensive architectural services.
</p>
</li>

<p class="MsoListParagraphCxSpLast"><span class="Heading2Char"><span
        style="font-size: 13.0pt; line-height: 115%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></p>
</ol>
<ol class="list-decimal">
<h3 class="font-medium"> Design Parameters involved in Landscape</h3>

<p class="text-sm">
    Aesthetic landscape compositions are congenial solutions for a space, which form an inseparable unit with
    buildings or town planning. So it is evident that landscape design
    architects are integrated into the project team right from the start, like
    structural or services engineers. The Foundation aspect in landscape design
    architect as follows – </p>
<li class="ms-5">
<h3 class="font-medium">Horizontal aspects –</h3>

<p class="text-sm">
    The general structuring of outdoor areas in context with the surroundings is
    regarded as a horizontal aspect. This is a fundamental organization
    following considerations like idea, function, design and form. It can
    produce horizontal results like paving,
    lawns etc. and also buildings, trees, pergolas etc.
</p>
<p class="text-sm">
    According to concept, items can be related to each other, repeated or contrasted; or a
    number of items can be superimposed. Open areas can, for example, continue
    themes or materials from</p>
<p class="text-sm">
    Buildings or provide a contrast. The ideal is to produce a central theme without
    functional limitations and then develop a design to make it readable.
</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Vertical aspects–</h3>

<p class="text-sm">

    It is the concepts for outdoor areas derive from the fundamental horizontal
    aspects and substantiate them. Not only is the selection of materials
    important but also the spatial contexts of the immediate surroundings. If
    there is a dip or a rise in the field of view, this lends the space to
    different interpretations. On the peak of a rise or in an open area, a roof,
    object or shelter can offer an impression of spatial definition. In street
    environments, trees can reduce the proportions of high buildings to a human
    scale and create small spaces within large. Vertical aspects, whether built
    or planted, should be to a sensible scale and integrate seamlessly into the
    overall concept of landscape architecture.</p>
  </li>
  <li class="ms-5">
<h3 class="font-medium">Form of illustration</h3>
<p class="text-sm">
    The decision how to illustrate with plans or drawings depends greatly on the
    stage of the project work. In the preliminary design and actual design
    phases, hand sketches and drawings can even today still contribute to a
    project’s presentation. At these stages,forms of illustration have a great
    significance.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Design of earthworks –</h3>    

<p class="text-sm">
    Modelled areas of ground are generally perceived as pleasant and interesting which
    can have a strong effect on the perception of as pace. The human eye looks
    for viewpoints and fixed objects in an open area. An example of this is the
    common hilly landscape with meadows, farmland and isolated trees in open
    man-made countryside. This impression can be achieved with intentionally
    designed terrain modelling as an addition to vertical structures or plants.
    Homogeneously &nbsp;occupied
    areas (lawns, ground-cover planting of uniform height, paving), with sunken
    centres in particular,make spaces seem larger. Wavy or hilly ground
    modelling can also enlarge the impression of space. According to the
    situation,this can enable economic synergies to be gained through the
    management of earth quantities. While designing landscape, there are many
    factors compacted like Preservation of topsoil, Slope Protection, Soil
    formation, Soil Loosening, soil improvement,&nbsp; etc.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Design aspects of walls and fences –</h3> 
<p class="text-sm">During
    the planning stage of landscape design it should generally be noted that
    walls and fences form vertical optical barriers. This should be used
    intentionally to create spaces or particular views. Individual spaces can be
    created out of&nbsp;large areas
    either geometrically or also organically. The selection of materials should
    consider the overall design concept. For Instance, paving can be of materials natural stone,
    brick etc. that ‘grow out of’their original location, and can be continued
    into walls to create a tranquil and homogeneous effect. Walls and fencing
    offer a multitude of design forms and types. Like, Wooden Fences, Metal
    Fences, Metal mesh or grilles etc.</p>
  </li>
<li class="ms-5">

<h3 class="font-medium">Freestanding walls and Retaining Walls –</h3>
<p class="text-sm">Freestanding
    walls are only subject to damp from the soil through the foundations and
    there less problematic in the choice of materials. Whereas Retaining walls
    can be self-supporting of concrete with facing brick or of dry stone.
    </p>
  </li>
  <li class="ms-5">
<h3 class="font-medium">Copings– </h3>

<p class="text-sm">The
    tops of walls must be protected against rain and snow by covering them with
    large slabs or stones. The coping element should&nbsp;have
    a cross-fall of at least 0.5%. Longitudinal joints in the coping are not
    allowed and butt joints must be at right angles to the&nbsp;wall
    centre-line.</p>
  </li>
  <li class="ms-5">
<h3 class="font-medium">Pergolas and trellises –</h3>
<p class="text-sm"
style="margin-left: 1.0in; mso-add-space: auto; text-indent: -1.0in; mso-text-indent-alt: -.25in; mso-list: l2 level1 lfo2;">
<span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">In
    addition to the selection of a material for the planned pergola, its
    position within the outdoor area needs to be considered carefully.</span>
</p>
<p class="text-sm"
style="margin-left: 1.0in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Large
    pergolas form spaces almost like buildings, and should be justified by their
    function or particular aesthetic value. Pergolas can lead to special places
    or viewpoints and can be used to divide spaces and/or as a sitting area
    Pergolas with climbing plants should be detailed in accordance with the
    particular characteristics of the intended plant (spacing of supports for
    climbing or winding plants).</span></p>
  </li>
  <li class="ms-5">
<h3 class="font-medium">PATHS, PAVING, STEPS –</h3>
<p class="text-sm"
style="margin-left: 1.0in; mso-add-space: auto; text-indent: -1.0in; mso-text-indent-alt: -.25in; mso-list: l2 level1 lfo2;">
<span
    style="font-size: 18px; text-indent: -1in; color: var( --e-global-color-text ); line-height: 115%;">For
    the design of paths and paved areas, questions of
    proportion&nbsp;</span><span
    style="font-size: 18px; text-indent: -1in; color: var( --e-global-color-text );">are
    important and the selection of materials is decisive. Firstly,the correct
    dimensions for path width, free paved areas and enclosed spaces need to be
    determined according to the use&nbsp;</span><span
    style="font-size: 18px; text-indent: -1in; color: var( --e-global-color-text );">and
    surroundings. Where as Steps overcome height differences: they are therefore
    always&nbsp;</span><span
    style="font-size: 18px; text-indent: -1in; color: var( --e-global-color-text );">Significant
    as a vertical design aspect and require detailed matching to the overall
    theme. Flat and wide steps with low risers appear softer, more spacious and
    stronger in design. The steeper&nbsp;</span><span
    style="font-size: 18px; color: var( --e-global-color-text );">and narrower
    the steps, the more functional the impression.</span></p>
</li>
<li class="ms-5">
<h3 class="font-medium">Rainwater Management and drainage–</h3>
<p class="text-sm"
style="margin-left: 1.0in; mso-add-space: auto; text-indent: -1.0in; mso-text-indent-alt: -.25in; mso-list: l2 level1 lfo2;">
<i><span
        style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Rainwater</span></i><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">
    management is urgently suggested for ecological and economic reasons in
    order to preserve the natural rainwater cycle as far as possible. The basic
    principle of rainwater management is to avoid, reduce or at least greatly
    delay surface water running off into the drains where it arrives or in the
    immediate vicinity. Certainly, Drainage is generally differentiated into
    linear or point drainage.Depending on the surfacing, surface falls should be
    provided to drain surface water appropriately at all times of year.</span>
</p>
</li>
</ol>
<p class="MsoListParagraphCxSpLast"><span class="Heading2Char"><span
        style="font-size: 13.0pt; line-height: 115%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></p>
<h3 class="font-medium"> FAQ about home, Terrace or any Landscape Design</h3>
<ol class="list-decimal">
<li class="ms-5">
<h3 class="font-normal">What are the 6main types of landscapes?</h3>
<p class="text-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">There
    are different types of landscapes. https://en.wikipedia.org/wiki/Landscape,
    like, desert, taiga, wetland, mountain range, cliff, littoral zone, coast,
    tundra, shrub land, forest, rain forest, woodland, moors etc. </span></p>
</li>
<li class="ms-5">
<h3 class="font-normal">What is the difference between architect and landscape architect?</h3>
<p class="text-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Architect
    Design structures, such as residential buildings or commercial buildings,
    whereas Landscape architects Provides plan for the outdoor areas around
    structures, as design work is the fundamental difference between architect
    and Landscape Architect.</span><span
    style="font-size: 18px; color: var( --e-global-color-text );">&nbsp;</span>
</p>
</li>
<li class="ms-5">
<h3 class="font-normal">How do I find landscape architect in pune?</h3>
<p class="text-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Speak
    with number of landscape architect and discuss with them about your budget
    and project. You can take reference from your contact also. The best way to
    post enquiry on </span><a
    href="https://foot2feet.com/construction-services/architect-in-pune/"><span
        style="line-height: 115%; color: windowtext;">https://foot2feet.com/construction-services/architect-in-pune/</span></a><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">
    about architectural services, here you will get quotation from several
    architect.</span><b
    style="font-style: inherit; color: var( --e-global-color-text );"><span
        style="mso-bidi-font-size: 10.5pt; line-height: 115%; font-family: 'Segoe UI','sans-serif'; color: #5b9bd5; mso-themecolor: accent1;">&nbsp;</span></b>
</p>
</li>
<li class="ms-5">
<h3 class="font-normal">What is cheapest rock for landscaping?</h3>
<p class="font-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Natural
    Landscape is an environment which exist even in man’s absence, certainly its
    not affected by human activities. In the same vein Manmade landscape is the
    art of designing the drives, lawns, walks, gardens and shrubs&nbsp; with the
    help of natural elements like stones, bricks, water, landforms and many
    more.</span><b
    style="font-style: inherit; color: var( --e-global-color-text );"><span
        style="mso-bidi-font-size: 10.5pt; line-height: 115%; font-family: 'Segoe UI','sans-serif'; color: red;">&nbsp;</span></b>
</p>
</li>
<li class="ms-5">
<h3 class="font-normal">How do you landscape the front of your house?</h3>

<p class="text-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Crushed
    gravel and pea gravel are the cheapest landscapes rocks.</span><span
    style="font-size: 18px; color: var( --e-global-color-text );">&nbsp;</span>
</p>
</li>
<li class="ms-5">
<h3 class="font-normal">What is difference between natural and man made landscape?</h3>

<p class="text-sm"
style="margin-left: 1.25in; mso-add-space: auto;"><span
    style="mso-bidi-font-size: 11.0pt; line-height: 115%; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-bidi-language: AR-SA;">Enormous
    foundation planting along the front of your house will make good landscape
    look.&nbsp;</span></p>
    </li>
    </ol>`,
    "largeIcon": "landscape.webp",
    "smallIcon": "landscape.png",
    "metaDescription": "Get Planting contractor, paving contractor, Maintainance Landscape Contractor in Pune for Bungalow. Free Consultation. +91 8282924242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Top Landscape Contractor in Pune - Foot2Feet",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "How much of the area is to be landscaped?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 500 sq.ft", "500 – 2000 sq.ft", "2000 - 5000 sq.ft", "Above 5000 sq.ft"]
      },
      {
        "title": "What services you require?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Drawing only", "Turnkey"]
      },
      {
        "title": "Would you consider online design service?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Yes", "No", "Need to talk to the designer", "May or may not"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "interior-designer",
    "displayText": "Interior Designer",
    "id": "interiorDesigner",
    "descriptionHTML": `<ol class="list-decimal">
    <li class="ms-5">
  <h3 class="font-medium">Know More about Interior Designer</h3>

  <p class="text-sm">Art and science of enhancing the interior of a building to achieve a healthier
  and more aesthetically pleasing environment is the core purpose of an Interior
  Designer. Many types of drawings, sketches, and diagrams are used in the design
  of buildings and spaces within. 3d views are used to explore and refine home
  décor ideas and information as the design process takes place.</p>
  </li> 
  <li class="ms-5">
<h3 class="font-medium"> Roles of an Interior Designer –</h3>
<p class="text-sm">An interior designer is not only the advisor to the client. But also&nbsp; the
    contractor in their procurement of product layouts, plans and installation of
    materials chosen for the design scheme. However, an interior designer practices
    a discipline that looks after designing all aspects of the interiors of an area.
    In addition it includes elements like flooring, windows, walls, lighting,
    furniture, doors and miscellaneous design items. The purpose of interior design
    is ordinarily to create a place that is both comfy nd appealing.</p>
 </li>
 <li class="ms-5">
<h3 class="font-medium">Time require for House Interior</h3>
<p class="text-sm">On an average, Designer will take 15 to 20 days to design the 2Bhk flat Interior,
    3Bhk Flat Interior or 4Bhk Flat Interior. When Interior Designer Finalize the
    design as per your requirement then the contractor will take 25 – 60 days to
    execute it. However, civil work like increasing the space, removing of a wall,
    kitchen extension may add on 15 – 20 days more depending upon the size of the
    work.</p>
</li>
<li class="ms-5">
<h3 class="font-medium">Cost required for Interior design</h3>
<p class="text-sm"><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">Cost
        required for the Interior design is totally depends on the architect you
        select. As some of Interior designers may charge the cost for 2 Bhk flat
        Interior design on lump sum basis on other hand some Interior design
        architect may charge at from 7.5% of total cost of interior project. You can
        also check the professional fees taken by architect&nbsp;</span><span
        style="color: #0000ff;"><a style="color: #0000ff;"
            href="https://www.coa.gov.in/index1.php?lang=1&amp;level=2&amp;&amp;sublinkid=299&amp;lid=86"
            target="_blank" rel="noopener"><u>Click here</u></a></span></p>
</li>
<li class="ms-5">
<h3 class="font-medium">Most Popular Styles of Home Designs</h3>
<p class="text-sm">Interior design options for homeowners are vast. Home Decoration contrast with
    some looks based entirely on functionality and efficiency while other styles
    share overlapping elements. With so many styles, homeowner’s have the option of
    following a specific genre closely or tailoring a mixture of genres into their
    homes. Before choosing a style, get to know all your options here with to the
    most popular styles amongst homeowners today as follows –</p>
<p class="text-sm "> <span class="font-normal">i. Modern
    Interior Design-</span> These sleek interiors are open and airy, with minimal clutter.
    Simple color schemes are chosen for walls, ceilings, doors, cabinets, and light
    fixtures. A neutral palette using grey, cream, white, or black is most often
    used. However, colorful palettes can be used to create a bolder appearance if
    desired. Regardless of the color palette, the variation of colors used is kept
    to a minimum for a given space.<br><span class="font-normal">ii. Contemporary Interior Design-</span>
    This style overlaps with modern and transitional,
    but has its own defining characteristics. Contemporary interior design is much
    more a reflection of “current” decor. The look is never outdated or overstated
    in appearance, but instead takes on a refined and approachable
    appearance.<br><span class="font-normal">iii.Minimalist Interior Design –</span>
     Minimalism has become very popular in recent years.
    It’s a style based on efficiency and practicality. Colors for ceilings,
    cabinetry, and floors are generally muted tones, white and grey are commonly
    used for walls. Lighting is purely functional and never overly decorative.
    Minimalist design works well for both small and large spaces. Generally, this
    design is popular for open-floor plans and works well with natural
    lighting.<br><span class="font-normal">iv. Mid-century Interior Design – </span>
    This design style takes a sleeker and more refined
    approach to looks from the 1950s and 1960s. Mid-century style has always been
    based on functionality, which increased the popularity of the style during its
    original era and has encouraged its resurgence today. It’s a timeless style that
    you can expect to see in the spotlight for years to come. Older and younger
    generations alike are pursuing this interior design style for their
    homes.<br><span class="font-normal">v.Industrial
    Interior Design-</span> This style pairs a “raw” aesthetic with utilitarian
    installations and lighting. Minimal furnishings are used to achieve this look
    and quite often composed of salvaged items. Or, the items can take on the
    appearance and textures of salvaged materials. Reclaimed wood and metal are
    popular elements for this design style.</p>
<p class="text-sm">
        <span class="font-normal">vi.Bohemian Interior Design</span><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">–
        This style stands on its own but is comparable to an eclectic style. It
        borrows and fuses elements from other styles in a successful manner. It’s a
        style that borrows from the East, with large Mediterranean archways and
        sometimes ornate decorative metal work on cabinetry, doors, or mantles. It
        incorporates bold colors, patterns, and elegance with a touch of rustic
        flair.</span></p>
<p class="text-sm"><span class="font-normal" >vii.Rustic Interior Design
        – </span><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">Rough-hewn
        wooden ceiling beams, unpolished barn-wood floors, and warm low lighting,
        theses are all characteristics of what you’ll encounter in a truly rustic
        home. Traditionally, this is a style that is represented by cabins and
        farmhouses.</span></p>
<p class="text-sm">
        <span class="font-normal">viii.Traditional
        Interior Design – </span><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">This
        style plays on elements from old world Europe. Classic European design uses
        ornate moldings, dark cherry wood, and organic lines. It contrasts dark
        reds, browns, blacks, and blues against lighter earth tones. This style
        incorporates luxurious textiles like silk and velvet.</span></p>
<p class="text-sm"><span Victorian class="font-normal">ix.Victorian
        Interior Design –</span><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">&nbsp;</span><strong
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">&nbsp;</strong><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">This
        style works well with older Victorian homes and for homeowner’s who love
        antiques. It’s a luxurious and elegant taste that is anything but simple or
        utilitarian. This interior design style uses deep rich, dark color palates
        and bold patterns. The walls are commonly covered in patterned bold wall
        paper and the floors are built from dark-hued solid wood.</span></p>
<p class="text-sm"><span class="font-normal">x.Art
        Deco Interior Designer – </span><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">Art
        Deco has a unique and distinct appearance pulling from the parlor style of
        the 1920s and the contemporary art world. In the early 1900s, this style
        became quite popular in Europe and the United States. Influenced heavily by
        Art Nouveau and Cubism, it’s an artistic and streamlined style.</span></p>
<p class="text-sm"><span
        style="font-size: 18px; color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family );">&nbsp;</span>
</p>
<li class="ms-5">
<h3 class="font-medium"> Factors involved in 2 Bhk Flat Interior</h3>
<p class="text-sm">As design development,the evolution of a 2Bhk flat interior is not a sequential
    process but a process of continuing interaction, feedback, and reevaluation.
    Interior designers benefit from gaining an understanding of human behaviors as
    it relates to privacy, territoriality, and other issues.&nbsp;</p>
<p class="text-sm">

    Bedroom Design – Bedrooms go well beyond the minimal to include various
        ancillary functions such as entertainment, study, and reading and therefore
        can include an array of furnishings, including items used for storage such
        as dressers, chests,shelves, night tables, and clothing. Seats or chairs,
        lamps, and mirrors are often included, as are desks. It is especially common
        to include desks in rooms meant for children or students, as the bedroom
        commonly serves as a study area.<br>
    Kitchen Interior – Kitchens that meet the lifestyles, culinary interests,
        and aesthetic preferences of owners are important components of custom home
        design as well as new construction and kitchen remodeling.Kitchen remodeling
        continues to be a significant component of the construction industry.<br>
    Bathroom Interior Design- Bathrooms have proliferated in quantity in our
        homes because many home owners prefer a bathroom for each bedroom or, at the
        very least, an owner’s suite with bathroom, a youngster’s and/or overnight
        guest’s bathroom, and a partial bathroom—often called a powder room
</p>
</li>
<li class="ms-5">
<h3 class="font-medium"> FAQ about Interior Designer</h3>
<h3 class="font-normal">i.How much
    does it cost to furnish a 1000 Sqft house?</h3>
<p class="text-sm">Getting good and fast service for house interior is one of the toughest
    challenge. It costs around 8-10 Lakhs for a 1000sq.ft. of house interior. Also
    interior design range is very wide. It sometimes also goes aroung 15-20 lakhs
    for the same area. The entire game is of the materials we use.</p>

<h3 class="font-normal">ii. What is 60 30 10 decorating rule?</h3>
<p class="text-sm">The 60 30 10 is a classic decor rule which adds to create a color palette for a
    space, it indicates that decorate 60% of the room with dominant color, decorate
    30% of the room with secondary colour or texture and the remaining 10 % of the
    space should be an accent</p>
<h3 class="font-normal">iii. What is the most popular furniture style?</h3>
<p class="text-sm">In modern days, Mid-Century Modern is the style known for the most iconic
    furniture.</p>
<h3 class="font-normal">iv. How many colors should a house have?</h3>
<p class="text-sm">Generally for cohesive color palette use no more than 5 distinct color throughout
    your home, so u can choose a white, neutral and 3colors. Certainly that doesent
    mean you are limited to only 5 paint colors</p>
<h3 class="font-normal">v.How long does it take to design the interior of house?</h3>
<p class="text-sm">On an average it takes about 5 -6&nbsp; weeks to complete a 2 bhk house interior.
    For larger house say 3 or 4 bhk it takes around 8 -10 weeks. The process does
    not include two or three initial design concepts, final design. Desiging takes
    around 15 -20 days.</p>
<h3 class="font-normal"> vi. How do I decorate single room apartment?</h3>
<p class="text-sm">Firstly, you need to decide and to do the segregation of areas like which areas
    would house the bed, sofa or chairs, and the kitchen. Secondly dividing the
    room, keep in the mind the window placement of your room and artificial light
    too. Smartly planning the layout of single room apartment will not only create
    some privacy but also it gives overall decor and lighting of the room according
    the function it would be used for.</p>
<h3 class="font-normal">vii. What is the most popular interior design style?</h3>
<p class="text-sm">Modern interior, Mid – century Modern, Minimalist, Scandinavian, Industrial
  style, contemporary interior design, urban style interior, exposed brick and
  traditional or classic interior style are the most popular interior design
  style.</p> 
  </li>`,

    "largeIcon": "interiorDesigner.webp",
    "smallIcon": "interiorDesigner.png",
    "metaDescription": "Looking for Home Interior Designer in Pune ? We provide complete end to end service in interior design execution. Fill the form below we will contact you soon. Services Related to Home Renovation Contractor 2 -3 Bhk Flat Interior Furniture layout, design, False Ceiling, Paint, Wallpaper, Artifacts etc. Restaurant Interior Interior of cafe, … Home Interior Design Read More",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Home Interior Design - Foot2Feet",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "Specify property type",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "dependsOn": {
          "previousQuestion": 1,
          "previousOptions": ["Bungalow", "Residential building"]
        },
        "options": ["1BHK", "2BHK", "3BHK", "4BHK", "5BHK"]
      },
      {
        "title": "How much is your budget?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2 lakhs", "2 to 5 lakhs", "5 to 10 lakhs", "Above 10 lakhs"]
      },
      {
        "title": "What services you require?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Drawing only", "Turnkey", "Design and installation"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "pmc",
    "displayText": "PMC",
    "id": "pmc",
    "descriptionHTML": `<ol class="list-decimal">
  <li>
  <h3 class="font-medium">What Is Project Management Consultant (PMC) In Redevelopement</h3>
  <p class="text-sm">In the modernization, construction industry generally deals with various types of
  construction sectors like, real estate and infrastructure. Real estate sector
  carved up into Residential, commercial, corporate and industrial. In the same
  vein, infrastructure in roads, railways, urban infrastructure, ports, airports
  and power. For instance to manage such kind of peerless projects requires
  expertise with organization and a throughout body of knowledge.</p>
<p>&nbsp;</p>
</li>
<li>
<h3 class="font-medium">What is Our Role as Project Management Consultant?
</h3>
<p class="text-sm">Foot2feet plays vital roles as a project management consultant and provides the
    services from inception to completion of projects. Our professional team of
    project management consultancy guides you during whole process with specialized
    skills and knowledge to assist you in making the best possible
    decisions.Likewise, PMC don’t have defined role but it varies on expertise
    needed in project management consultant. Few of expertise are as follows –</p>
<div>&nbsp;</div>
<p>&nbsp;</p>
</li>
<li>
<h3 class="font-medium"> Role as an Architect</h3>
<p class="text-sm" >
    1.An architect plays a vital role in preparing the financial feasibility of
    project, conduct technical analysis of offers made by the developer to you.
    <br>
    2.We design the plan as per bylaws of local planning authority.<br>
    Obtaining, checking &amp; approval to drawing.<br>
    3.Checking parking area, carpet area, utility &amp; service areas etc.<br>
    4.Analysis &amp; approval for submission drawing.<br>
    5.Analysis &amp; approvals for site execution drawings.<br>
    6.Consultations throughout construction process at every important situation.
    </p>  

<p><strong>&nbsp;</strong></p>
</li>
<li>
<h3 class="font-medium"> Role as an Civil Engineer</h3>
<p class="text-sm">
    1.A civil engineer would cross verify the RCC structure during construction.<br>
    2.Check quality of material used during the process of construction.<br>
    3.Check specifications used by builder during construction are per builders
        offer<br>
</p>
<p><strong>&nbsp;</strong></p>
</li>
<li>
<h3 class="font-medium"> Role as a legal advisor</h3>
<p class="text-sm">
    1.As an legal advisor, All theduties associated with advocate<br>
    2.Fulfilling Regulatory Guidelines<br>
    3.Consultation in order to avoid litigation, arbitration and legal conflicts<br>
    4.Stages involved in project management Consultancy<br>
    5.We focus our complete attention on the complete process of project management
    with our various specialist such as planners, architects, engineering designers,
    constructors, fabricators, material suppliers, financial analyst and
    others.&nbsp;<br>
</p>
<p>&nbsp;</p>
</li>
<li>
<h3 class="font-medium">Following are the major steps involved in our project management consultancy
    (PMC)-</h3>
<p class="text-sm">
        Pre-Construction Management–As the date of the commencement of the project
        approaches we take the complete ownership of entire project by tracing out
        the roadmap for our customers. However it includes advertising, monitoring
        and reporting pre-construction activities of the project. Most importantly
        the overall objective of the pre-construction project is to improve the
        efficiency and reduce time and cost.<br>
        The phases involved in pre-construction management as follows-
</p>
<p class="text-sm">
    1.Project Administration<br>
    2.Architects and consultants appointment<br>
    3.Value Management<br>
    4.Management and coordination of design<br>
    5.Planning Management<br>
    6.Cost Management<br>
</p>
<br>

<p class="text-sm">
    <p class="text-sm">Construction Management – During the construction period, we prepare
        construction management strategy, procedure, and manual and undertake the
        construction process management. In addition its necessary strategy of
        overseeing the all contractors supervision team at site and monitoring their
        established processes. The construction phase involves implementation of the
        construction plan by the project construction manager including day to day
        communication, as follows-</p>
  <p class="text-sm">      
    1.Planning Management<br>
    2.Contractors Appointment<br>
    3.Environment, Health and safety Management<br>
    4.Quality Management<br>
    5.Cost Management<br>
    6.Site Management<br>
    7.Co-Ordination with Client, Architect and other Consultants
    </p>
</p>
</li>
<br>
<li>
<h3 class="font-medium">Points to be noticed while selecting Project Management Consultancy</h3>
<p class="text-sm">Project Management consultancy is most effective and efficient when it is
    involved in total project lifecycle. Likewise, PMC brings the skills and the
    technical know – how required for the projects.&nbsp; Pmc provide the expertise,
    tools and manpower required in executing the project from planning to
    completion. Few factors are listed below –</p>
<p>&nbsp;</p>
<p class="text-sm">
    1.Most important factors in selecting PMC is that PMC must consists of
        Architects with expertise in corporation bye laws &amp; good command over
        various area calculations &amp; loop holes. This point becomes most
        important as very few architects/experts practice in byelaws / Liaisoning
        sector.<br>
    2.Need to give impartial services.<br>
    3.Require regular consultation for smooth process &amp; easy decision making<br>
    4.Transparency is important<br>
    5.Require technically strong team.<br>
    6.Must have one stop solutions for – legal, architectural, and financial,
        technical, structural consultant services.<br>
    7.Need to give good understanding of the project sevice scope<br>
    8.A good PMC Ensures Stringent Safety policy<br>
    9.Compliance and documentation<br>
</p>
</li>
<br>
<li>
<h3 class="font-medium">Responsibilities of Project Management Consultancy-</h3>
<p class="text-sm">PMC plays a lead role in project planning and implementation. Some of the
    responsibilities of Project management Consultancy includes-</p>
<p class="text-sm">
    1.Suggestion &amp; inputs for building specifications, corpus fund, additional
        area, amenity specifications, temporary accommodation arrangements.<br>
    2.Preparing final tender documents<br>
    3.Inviting tenders for developers / builders<br>
    4. Shorting tenders &amp; preparing comparison chart<br>
    5.Inspection of agreement documents, check whether all clauses which may bring
        conspiracy in future are clearly mentioned.<br>
    6. Checking developer strength, reputation &amp; obtaining all necessary
        documents from them<br>
  7.Verification of builder’s project report which explain how he is going to
        develop at the offers given by him.<br>
  8.Suggestion &amp; inputs on architects plan to improve qy of spaces, if
        possible.<br>
  9.Frequent periodic site visits.<br>
  10.Making Report of completion of all legal technical process which include
        various important factore revocation of power of attorney, occupancy
        process &amp;&amp; all other factors a society &amp; developer should do.
    <br>
  11.Suggestions on when &amp; how to process all those documents &amp; from whom
        it should be obtainede developer,&nbsp; CA , advocate, contractor, Pune
        corporation, Dy Registrar and any other government or private body.<br>
</p>
</li>`,
    "largeIcon": "pmc.webp",
    "smallIcon": "pmc.png",
    "metaDescription": "Project Management Consultants in Pune 100% Assured service. Free Quotation for your Project. Services Related to Project Management Consultants Project Feasibility Studies Gives the entire picture of the project helping for smooth execution. Planning & Schedule Management Planning of all the resources required according to the time. Construction Quality Management Keeps check on construction material … Project Management Consultants in Pune Read More",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Project Management Consultants in Pune - Foot2Feet",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "valuation",
    "displayText": "Valuation",
    "id": "valuation",
    "descriptionHTML": ` <ol class="list-decimal">
  <li>
  <h3 class="font-medium">Know more about Property Valuation </h3>
  <p class="text-sm">An Inspection carried out to assist determine the current market value of a
  property is a Property Valuation. In the same vein, property valuation is a
  detailed report of a property’s market value. So from the property valuation the
  bank need to be confident that it can recover any outstanding amount owned on
  property that the buyer default on their mortgage.</p>
  </li>

  <li>
<h3 class="font-medium"> Process for property valuation?</h3>
<p class="text-sm">A property valuation is often present as a report. Foot2feet’s Property valuation
    expert, does step by step process to get your property valuation.</p>
<p class="text-sm">Firstly, valuer understand the purpose and function of the valuation. It includes
    to estimate the market value of the property it includes value in use,
    investment value, and insurable value and for which function valuation is in
    process. Likewise, for to buy or &nbsp;sell a property, or to re-mortgage, need
    to file insurance claim for damage, and to appeal property tax.</p>
<p class="text-sm">Secondly, onsite inspection of property needs to be done. In this general
    information of property is required. Such as, the age, size, use of the property
    and equipment’s.</p>
<p class="text-sm">Thirdly, along with the information provided by client against property, Other
    informative data needs to be collected like real estate trends, including the
    local economy,&nbsp; and site–specific data and comparable properties if
    applicable etc. Finally, composing all this information, the property valuation
    report is ready.</p>
    </li>
<li>
<h3 class="font-medium"> Documents Required for Property Valuation</h3>
<p class="text-sm">Property valuation helps to determine the fair market value of a property at the
    time of property sale or purchase. The following documents are to be furnished
    along with the application form for Property Valuation –</p>
<p class="text-sm" >
  1.Proof of Ownership of property<br>
  2.Copy of Encumbrances Certificate<br>
  3.Aadhaar card<br>
  4.Voters Id<br>
  5.Proof Land Tax<br>
</p>
</li>
<li>
<h3 class="font-medium">Who does Property Valuation?</h3>
<p class="text-sm">Generally, Property valuation is undertaken by a real estate agent or independent
    valuer. A valuer is someone with a professional degree and license from the
    institution of valuers. While a property broker helps you to understand the
    pulse of market. On Foot2feet.com You will get number of Valuer for property
    valuation in pune</p>
    </li>
    <li>    
<h3 class="font-medium">When Property valuation is required?</h3>
<p class="text-sm" >A property valuation offer benefits to both the buyer and seller. It provides a
    clear indication of a property’s market value. Certainly property valuation
    reduces buyer’s risk of paying over the odds of a property. Report gives
    &nbsp;detail &nbsp;analysis of a property’s weakness. Hence&nbsp; it assist the
    seller to decide which renovations to make to enhance a property’s value. Most
    importantly, Property valuation is required as because their mortgage lender
    like bank or financial institutes request this. After that, Property valuations
    often required for financial reporting, taxation compliance, determining the
    amount of compensation given to land owners for easement of land acquisition and
    family law mediation.</p>
    </li> 
    <li>     
<h3 class="font-medium">FAQ about Property Valuation</h3>
<h2 class="text-sm"><strong style="font-size: 18px; color: var( --e-global-color-text );">1. What is
        Property valuation certificate?</strong></h2>
<p class="text-sm">Property valuation is a legal document which certifies the details of the entry
    in the Registrarof Land Values.</p>
<p class="text-sm"><span
        style="font-size: 18px; color: var( --e-global-color-text );">2.&nbsp;</span><strong
        style="font-size: 18px; color: var( --e-global-color-text );">What are the
        five methods of property valuation?</strong></p>
<p class="text-sm">While evaluating a property, five main methods used, when conducting a property
    evaluation, i.e. comparison, profits, residual, investment and rental value of a
    property.</p>
<p class="text-sm"><strong style="font-size: 18px; color: var( --e-global-color-text );">3. What is
        difference between a property appraisal and a property valuation?</strong>
</p>
<p class="text-sm">A property valuation is a detailed report of a property’s market value. Whereas,
    property appraisal &nbsp;is the process of creating an estimate of value for
    real estate.</p>
<p class="text-sm"><strong style="font-size: 18px; color: var( --e-global-color-text );">4. How long
        does a home inspection typically take?</strong></p>
<p class="text-sm">Generally it depends upon the size of the home and number of rooms. Whereas an
    average time to home inspections it takes upto 2 to 3 hrs.</p>
<p class="text-sm"><strong style="font-size: 18px; color: var( --e-global-color-text );">5. How is
        land value calculated?</strong></p>
<p class="text-sm">Land Value is calculated by considering the shape, frontage on the main road,
  zone use, plot coverage, Floor space index and instances of built-up properties
  in the locality.</p>
  </li> `,
    "largeIcon": "valuation.webp",
    "smallIcon": "valuation.png",
    "metaDescription": "Property Valuation in Pune Get valuation for any property, flat, land, industry anywhere in Pune Services Related to Property Valuation Residential/Commercial Valuation Kitchen Trollies, Kitchen platform, Kitchen tiles, etc replacement. Land Valuation Plumbing, Tiles, Shower, Commode, Basin, Ceiling replacement. Rental Property Valuation Single room, full home, painting, false ceiling, furniture, etc Looking for Assured Property … Property Valuation Read More",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Property Valuation - Foot2Feet",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "estimator",
    "displayText": "Estimator",
    "id": "estimator",
    "descriptionHTML": "",
    "largeIcon": "estimator.webp",
    "smallIcon": "estimator.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Consultants",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "What type of estimation you require?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Preliminary estimate", "Detailed estimate", "Quantity estimate", "Cost estimate"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "mojani",
    "displayText": "Mojani",
    "id": "mojani",
    "descriptionHTML": `<ol class="list-decimal text-justify">
    <li class="ms-5">
   <h3 class="font-medium mt-2">मोजणी चे प्रकार कोणते?</h3>
    
   <ol class="list-[upper-roman] ml-10">
   <li>
   <h3 class="font-bold mt-2" > हद्द कायम मोजणी</h3>
<p  class="text-sm mt-1">हद्द कायम मोजणी म्हणजे सर्वसाधारण मोजणी . यामध्ये प्रत्येक्ष जागेवर मोजणी
  करून हद्दीच्या खुणा केल्या जातात तसेच कागदावर (मोजणी नकाश्यावर ) जागेचा
  नकाशा ,सर्व हद्दी ,लगतचे प्लॉट नंबर आणि गट&nbsp; नंबर किंवा सर्वे नंबर
  नुसार जागेचे लोकेशन दर्शविले जाते.</p>
<p  class="text-sm">साधारणतः सर्व ठिकाणी हद्द कायम मोजणी लागते</p>
<p  class="text-sm">उदाहरण : जागा मोजणी ,बांधकाम परवानगी,जमीन खरेदी विक्री इत्यादी</p>
</li>
<li>
<h3 class="font-bold mt-2"> बिनशेती मोजणी</h3>
<p class="text-sm mt-1">प्लॉटींग करताना अंतिम नकाशा मंजुरीसाठी दाखल करताना बांधकाम चे भोगवटा पत्र
  मिळवणे, रोड आरक्षण,अमिनिटीस्पेस ताब्यात देताना बिनशेती मोजणीचा वापर
  होते. (एकदा बांधकाम नकाशा मंजूर केल्यानंतर हद्द कायम मोजणी एवजी बिनशेती
  मोजणी करणे कधीही योग्य.)</p>
</li>
<li>
<h3 class="font-bold mt-2 ">कोर्ट वाटप मोजणी /कोर्ट कामिशन मोजणी</h3>
<p class="text-sm mt-1">जमिनीच्या हद्दीमध्ये वाद निर्माण झाल्यास किवा शेजारील जागामालक सही देत
  नसेल किवा कोणी अतिक्रमण केले असल्यास हद्द कायम करणे कमी कोर्ट वाटप मोजणी
  /कोर्ट कामिशन मोजणी केली जाते.</p>
  </li>
</ol>

<li class="ms-5 mt-2">
    <a class="font-medium ">मोजणीचे फी प्रमाणे प्रकार
        किती व कोणते ?</a></h3>

    <p class="text-bold mt-1">फी प्रमाणे मोजणीचे चार प्रकार पडतात .</p>
    <ol class="list-[upper-roman] ml-10">
    <li>
    <h3 class="text-bold mt-1">साधी मोजणी (कालावधी साधारणतः६ महिने )</h3>
    <p class="text-sm mt-1">या प्रकारात सर्वात कमी फी लागते. जेव्हा बांधकाम करणे,प्लॉट विकणे,वाटणी
        करणे, अशा कामांचा विचार चालू असतो अशावेळी लगेच साधी मोजणी साठी अर्ज
        करावा .</p>
    <p class"text-sm">कामाचे निर्णय आणि नियोजन होईपर्यंत ६ महिने लागू शकतात. यामुळे पैश्याची
        बचत होऊन वेळेचा सद उपयोग होतो.</p>
    </li>
    <li>
    <h3 class="text-bold mt-1">२ तातडी मोजणी (कालावधी साधारणतः३ ते ४ महिने )</h3>
    <p class="text-sm mt-1">या प्रकारात साधी मोजणीच्या दुप्पट फी लागते परंतु इतर प्रकारापेक्षा कमी फी
        लागते.</p>
    </li>
    <li>
    <h3 class="text-bold mt-1">अति तातडी(कालावधी साधारणतः २ ते ३ महिने )</h3>
    <p class="text-sm mt-1">या प्रकारात २ ते ३ महिन्यात मोजणी घेउन प्रकरण लवकर मार्गी लागते. खरेदी
        /विक्री किवा बांधकाम परवानगी वेळी जलद निर्णया वेळी या प्रकारची मोजणी कमी
        येते. परंतु साधी मोजणीच्या तिप्पट फी लागते.</p>
    </li>
    <li>
    <h3 class="text-bold mt-1">अति अतितातडी (कालावधी साधारणतः १५ दिवस ते १ महिने )</h3>
    <p class="text-sm mt-1">अति महत्त्वाचे कामी १५ दिवस ते १ महिन्यात या प्रकाची मोजणी केली जाते
        परंतु याचे सरकारी चलन खूपच जास्त असते .</p>

    <p class="text-sm">समजा साधी मोजणी चे चलन १००० रु असेल तर</p>
    <p class="text-sm">तातडी मोजणी = २००० रु</p>
    <p class="text-sm">अति तातडी मोजणी = ३००० रु</p>
    <p class="text-sm">तर अति अतितातडी मोजणी = १२००० रु असते .</p>
    <p class="text-sm">अति अतितातडी मोजणी फी साधी मोजणीच्या चार पट असते .</p>
    <p class="text-sm">एकदा केलेली मोजणी ४ वर्ष चालत असल्याने तसेच वरील सर्व बाबींचा विचार करून
        योग्य वेळी निर्णय घेऊन तातडी किवा अति तातडी किवा अति अतितातडी मोजणी
        करावी .</p>
    </li>
    </ol>
<li class="ms-5 mt-2">

    <a class="font-medium "> हद्दकायम मोजणी मान्य नसल्यास काय तरतुद आहे ? </a>

    <p class="text-sm mt-1">मोजणी मान्य नसलेस निमताना मोजणीकामी उप अधीक्षक भूमी अभिलेख यांचेकडे अर्ज
        करता येतो (अपिल अर्ज)</p>
</li>
<li class="ms-5 mt-2">

    <a class="font-medium"> कोर्टवाटप मोजणी कशी केली
        जाते ? </a>

    <p class="text-sm mt-1">उत्तरकोर्टाच्या हुकुम नाम्याप्रमाणे मोजणीची कार्यवाही करण्यात येते.
    </p>
    </li>
<li class="ms-5 mt-2">

    <a class="font-medium"> कोर्ट कमिशन मोजणी प्रकरणात
        कोणत्या कागदपत्रांची आवश्यकता असते ? </a>

    <p class="text-sm mt-1">मा. दिवाणी न्यायालयाकडील आदेश, तीन महातील अधिकार- अभिलेख (7/12) ,मोजणी फी
        भरल्याचे चलन, वादी-प्रतिवादींची नावे/ पत्ते चालु परिस्थिती प्रमाणे मोजणी
        करावयाच्या क्षेत्राच्या चतु:सिमा इत्यादी कागदपत्रे प्रकरणी आवश्यक असतात.
    </p>
    </li>
<li class="ms-5 mt-2">
    <a class="font-medium">कोर्ट कमिशन मोजणी कधी करता
        येते ? </a>

    <p class="text-sm mt-1">जमिनीचे हद्दीमध्ये धारकामध्ये वाद निर्माण झाल्यास दिवाणी न्यायालयात दावा
        दाखल करुन वादी – प्रतिवादी यांच्या बाजू/ म्हणणे ऐकुण निप:क्षपाती निर्णय
        होणेसाठी या विभागातील अधिकारी / कर्मचारी यांची कोर्ट कमिशनर नेमणुक होऊन
        मोजणीसाठी पाठविले जाते.</p>
</li>
<li class="ms-5 mt-2">
    <a class="font-medium"> बिनशेती मोजणी कधी करता येते
        ? </a>

    <p class="text-sm mt-2">एखाद्या जमिनधारकास त्यांच्या जमिनीची अकृषीक प्रयोजनासाठी वापर करावयाचा
        असलेस महाराष्ट्र जमिन महसुल अधिनियम 1966 कलम 44 अन्वये महसुल विभागाची
        परवानगी घेऊन कोणत्या प्रयोजनासाठी जमिनीचा वापर करावयाचा आहे. त्यानुसार
        धारकास मोजणी करणे कामी शर्त घातली जाते व तसे आदेशात नमुद केले जाते.</p>
  </li>
<li class="ms-5 mt-2">
    <a class="font-medium">मोजणी नक्कल कशी मिळवावी
        ?</a>

    <p class="text-sm mt-1">नक्कल अर्ज कोऱ्या कागदावर,आवश्यक त्या नकला मिळणे कामी ,
        गावांचे नाव, तालुका, जिल्हा इत्यादी नमुद करुन अभिलेखाचा प्रकार नमुद
        करावा. नकलेचा अर्ज कोणत्याही व्यक्तीस करता येतो. अर्जास 5 रु. कोर्ट फी
        तिकीट लावावे.अर्ज केल्यानंतर ३ दिवसात नक्कल मिळतात .</p>
</li>
<li class="ms-5 mt-2">

 
    
    <a class="font-medium">“ क ” प्रत </a></h3>
    <ol class="list-[upper-roman] ml-10">
    <li>

    <p class="text-sm mt-1">क प्रत हि मोजणी ची मूळ प्रत असते .क प्रत ही मालकास दिलेली मुल प्रत
        असते.जी एकदाच मिळते.</p>
        </li>
        <li>

    <p class="text-sm mt-1">अ व ब प्रत ही भूमी अभिलेख कक्षात रेकोर्ड ला असते .</p>
    </li>
    <li>
    <p class="text-sm mt-1">जेव्हा जागा मालकाला कोर्ट कामासाठी ,जमीन खरेदी विक्रीसाठी,मोजमापे करणे
        ,बांधकाम परवानगी ,झोने दाखला ,सरकारी परतावा&nbsp; या जागी मोजणीची प्रत
        जोडावयाची लागत असेल तर त्याने मोजणीच्या नाकाला जोडणे अपेक्षित आहे .( क
        प्रत किवा क प्रतची झरोक्स जोडणे योग्य राहणार नाही .)</p>
    </li>
    <li>
    <p class="text-sm mt-1">नकलेसाठी भूमी अधीक्षक कार्यालयाकडे विहित नमुन्यातील अर्ज केल्यानंतर ३
        दिवसात मिळतात .आपणास हव्या तेवढ्या नाकाला मिळत असल्याने मोजणी झाली कि
        लगेच ३ ते ५ नाकाला काढून घेणे योग्य राहील .</p>
        </li>
        </ol>
  </li>
<div class="elementor-element elementor-element-db0d8cf elementor-widget elementor-widget-text-editor" data-id="db0d8cf"
  data-element_type="widget" data-widget_type="text-editor.default">
  <div class="elementor-widget-container">
    <div class="elementor-text-editor elementor-clearfix">
      <h2 class="font-bold mt-2">Know More about Government Demarcation (Sarkari Mojani)</h2>
      <h2></h2>
      <p>Demarcation is important part that comes way before planning to build the house
        or commercial land. Demarcation of land means measurement copy of land / plot.
        This is done by survey . Some times by government some times by private company.
        Demarcation is geographical line that is crossed between the two plots. It
        involves both the owner as well as the neighbors land owner. Demarcation also
        known as Mojani services.</p>
      <h2></h2>
      <p>Surveyor performs the land survey and the authority gives the jamin Mojini
        certificate as per the type of certificate i.e sadhi mojani, atit atadi mojini,
        ati-ati-tatadi mojani the cost with respect to all these demarcation services is
        given blow in details. Maharashtra government’s national informatics center
        (NIC) has created a system in cooperation with revenue department which is
        called ‘Bhumi Abhilekh’ .Bhumi Abhlekh has created to provide land owner a smart
        card and it also promises to provide (7/12) utara on the online platform.</p>
      <h2></h2>
      <p>The land record is basically all rights related to land, registration, records of
        rights, tenancy and crop inspection registers etc. are protected in the land
        records. In this, the size of the land, nature of soil, land related to crops
        and its economic information is also included.</p>
      <h2></h2>
      <p>All type of land related service available. Government Mojani,Private survey, etc
        Assured Service, Timely Delivery. Call +91 8282924242</p>
      <h2></h2>
      <h2 class="font-bold">Where can I get Mojani Certificate?</h2>
      <h2></h2>
      <p>You are eligible for mojini certificate as long you own the land and the concern
        office for the demarcation certificate Commissioner and Director Land Records
        Office. In Pune, it is near council hall. Foot2Feet is the best councilor for
        these services.</p>
      <h2></h2>
      <h2 class="font-bold">When Demarcation Certificate is required?</h2>
      <h2></h2>
      <p>• Demarcation certificate is required as long as you own a land in your
        name.<br>• you also need mojani certificate in case of dispute land. Proof of
        ownership of any land is proof. On purchasing the land, the background and truth
        of the seller are also known.<br>• When the cell-transaction is complete then
        mojani certificate is required in the Sub-Registrar’s office. It is also needed
        for form credit / loan from the bank.<br>• It is required in the court during
        any civil trial. Apart from this, demarcation certificate is also sometimes
        required for personal reasons.</p>
      <h2></h2>
      <p><br>With bhumi abhilekh, people will be able to get all the information of a
        particular land online. This means that the purchase of land, the information
        related to it, can be obtained by just one click. The mutation entry will also
        be automatic; they will not have to visit Talathi. People will also be able to
        know that a person is registered in whose name and which land is government.</p>
      <h2></h2>
      <h2 class="font-bold">Document requirement for demarcation?</h2>
      <h2></h2>
      <p>To get Land demarcation certificate, We need following-</p>
      <h2></h2>
      <p>• 7/12 certificate: For the proof of land ownership<br>• Mojani Application:
        Application to apply for mojani certificate<br>• Name and address of adjacent
        owner<br>• ID proof of applier: PAN card, Adhar card, etc.</p>
      <h2></h2>
      <h2 class="font-bold">Various types of Mojani</h2>
      <h3 class="font-semibold">• Sadhi Mojani</h3>
      <h3 class="font-semibold">• Tatadi Mojani</h3>
      <h3 class="font-semibold">• Ati-tatadi mojani</h3>
      <h3 class="font-semibold">• Ati-Ati-tatadi mojani</h3>
      <h2 class="font-bold">Time duration to get Mojani Certificate (MojaniShulak)</h2>
      <h2></h2>
      <p>1) Sadhi Mojani: It takes 6 to 8 months to get the mojani certificate with sadhi
        mojani<br>2) Tatadi Mojani: It takes 3 to 4 months to get the mojani certificate
        with tatdi mojani<br>3) Atitatdi Mojani: It takes 1 to 2 months to get mojani
        certificate with atitatadi mojani<br>4) Ati-Ati-tatadi mojani: It takes 15 days
        to 1 month to get mojani certificate</p>
      <h2></h2>
      <h2 class="font-bold">Cost for Mojani certificate</h2>
      <h2></h2>
      <p>• Sadhi Mojani : It takes Rs 3000 to 5000 to get demarcation certificate with
        sadhi mojani.<br>• Tatadi Mojani It takes Rs 6000 to 10000 to get demarcation
        certificate with tatadi mojani<br>• Ati-tatadi mojani: it takes Approximately Rs
        10,000 to 20,000 to get demarcation certificate with ati-tatadi mojani<br>•
        Ati-Ati-tatadi mojani: It takes 4 times of tatadi mojani with ati-ati-tatadi
        mojani</p>
      <h2></h2>
      <p>* The prices may vary with respect to places, types of mojani.</p>
      <h2></h2>
      <h2 class="font-bold">Additional Information About Government Demarcation</h2>
      <h3 class="font-semibold">Hadda kayam mojani:</h3>
      <h2></h2>
      <p>Boundary fixation demarcation (Hadda kayam Mojini) is a simple calculation. These
        include the actual location of the boundary markings, as well as the map of the
        space on the paper (counting map), the location of all the boundaries, the plot
        numbers and the group numbers or survey numbers. Usually all places require
        permanent calculation Examples: space counting, construction permit, land
        purchase and so on.</p>
      <h2></h2>
      <h3 class="font-semibold">Bin Sheti Mojani (Non Agricultural or NA demarcation):</h3>
      <h2></h2>
      <p>When plotting, the final map is used for obtaining construction occupancy
        letters, road reservation, and counting of uninspired calculations while filing
        for approval. (Once the construction map is approved, it is always advisable to
        do an arbitrary calculation instead of a permanent measurement.)<br>This
        measurement is also done by applying online for Jamin Mojani in Maharashtra at
        foot2feet.</p>
      <h2></h2>
      <h3 class="font-semibold">Court commission mojani</h3>
      <h2></h2>
      <p>court commission mojani is done in case of dispute between land boundaries or if
        landlord does not sign or encroach on encroachment.<br>Appeal can be made to the
        Deputy Superintendent of Land Records, Counting Appeal if the count is not
        allowed.</p>
      <h2></h2>
      <p>The counting proceedings are carried out as per the order of the North Court.</p>
      <h2></h2>
      <p>Documents are required in the case of orders from the civil court, rights of
        three Mahals – records (7/12), invoices for payment of counting fees, names of
        the defendants and defendants.</p>
      <h2></h2>
      <h3 class="font-semibold">Demarcation Certificate in case of land dispute</h3>
      <h2></h2>
      <p>In case of a dispute between the holders within the boundary of the land, a claim
        is filed in the civil court and the court / commissioner of the department /
        officer is sent for the counting of the plaintiffs and the defendants.<br>If a
        landowner wants to use their land for non-agricultural purposes, the purpose is
        to use the land for the purpose, with permission of the Revenue Department under
        section 44 of the Maharashtra Land Revenue Act, 1966. Accordingly, the holder is
        conditioned to calculate and is mentioned in the order.</p>
      <h2></h2>
      <h3 class="font-semibold">Certified Demarcation Certificate</h3>
      <h2></h2>
      <p>Duplicate (Certified) application should be provided on the blank paper,
        mentioning the required copy, mentioning the name of the village, taluka,
        district etc. The application can be made to any person. The application is
        available for Rs. You should get a court fee ticket.</p>
      <h2></h2>
      <h3 class="font-semibold">&nbsp;Find government on below link</h3>
      <h2></h2>
      <p><a
          href="https://bhumiabhilekh.maharashtra.gov.in/1035/Home">https://bhumiabhilekh.maharashtra.gov.in/1035/Home</a>
      </p>
      <h2></h2>
      <h3 class="font-semibold">“K prat”</h3>
      <h2></h2>
      <p>K prat is the original copy of the count . It is a child copy given to the owner.
        K and B copies are recorded in the land records cell. When the landowner is
        required to add a copy of the measurement to the court, for work, sale of land,
        measuring, construction permit, zoning certificate, government return, he / she
        is expected to attach a measuring cop</p>`,
    "largeIcon": "mojani.webp",
    "smallIcon": "mojani.png",
    "metaDescription": "All type of land related service available. Government Mojani,Private survey, etc Assured Service, Timely Delivery. Call +91 8282924242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Land Government Mojani - Just in 3 hours - Foot2Feet",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "private-land-survey",
    "displayText": "Private Land Survey",
    "id": "privateLandSurvey",
    "descriptionHTML": "",
    "largeIcon": "privateLandSurvey.webp",
    "smallIcon": "privateLandSurvey.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "Which type of survey do you want to do?",
        "type": "radio",
        "other": false,
        "otherIndex": 2,
        "options": ["Plain Table Survey", "Contour Survey", "I don’t have any idea"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "building-permissions",
    "displayText": "Building Permissions",
    "largeIcon": "buildingPermissions.webp",
    "smallIcon": "buildingPermissions.png",
    "id": "buildingPermissions",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 4,
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      }
    ]
  },
  {
    "url": "aviation",
    "displayText": "Aviation",
    "id": "aviation",
    "descriptionHTML": `<ol class="list-decimal">
 
  <h3 class="font-medium">Know more about Aviation</h3>
  <p class="text-sm">There are certain restrictions when it comes to building an infrastructure around
        airport. According to Airport Authority of India (AAI) No construction is
        allowed within 150 meters from runways. Area between 150-500 meters is reserved
        for airport operation buildings. This rule is applied for any of the
        infrastructure that is being built near runway. It includes building, chimney,
        flyover, crane, bill boards, power transmission lines wind turbine, etc.<br>The
        requirement of the Aviation NOC certificate is very important as it is the first
        step towards starting out with construction of your private or commercial
        architecture. The Aviation NOC or in some cases called as Elevation NOC involves
        AAI Ministry of Defense (MOD). The job to get this certificate may seem hectic
        process and that’s why we are here. Foot2Feet is airport NOC consultant that
        assists organizations and individuals to generate Aviation NOC
        certificate.<br>We will understand the information regarding Elevation NOC with
        the help of following question and answers:</p>
      <p>&nbsp;</p>
     
      <h3 class="font-medium">What does aviation NOC mean?</h3>
      <p class="text-sm">While building architecture around the airport area it is mandatory to issue no
        objection certificate from Airport Authority of India or from Ministry of
        Defense. It is because of safety measures. To grant the certificate, the builder
        or individuals must pass the parameter. The building must be under__ meters if
        it is being build on the land that is 600 meters away from runway. The parameter
        changes according to the location of the land. As soon as the builder gets this
        certificate, he can start the construction without any hustle</p>
        <p>&nbsp;</p>
      <h3 class="font-medium">Steps to get aviation NOC?</h3>
      <p class="text-sm">Even though this NOC is required to get started with the construction, getting it
        ready is a hectic job. Many of the builders spend their valuable time to issue
        the certificate. Considering this hustle, at Foot2Feet, we will assist you with
        everything that is required to get the NOC. Following are the step by step
        explanation to get NOC.</p>
      <p class="text-sm">1. To see whether you need to get the NOC or not, you
        need to check that on what Color Code Zoning Map (CCZM) code does your land
        comes in. AAI has decided some color coding for the land with respect to how far
        is it from airport. CCZM has different color codes. The color code is usually
        red, yellow, blue, green, etc. CCZM tells you whether to build or not. If your
        land comes under red zone then it will have some restrictions to build- even you
        are building a small hut. If you are building on land that comes under yellow
        and green zone then until some limitations you will not required certificate.
        The CCZM is calculated with respect to altitude and latitude.<br>2. When you
        know on what color zone your land is, you can move forward with next step that
        is getting AMSL height certificate from local authority. AMSL is the
        abbreviation for above mean sea level to issue this document; it takes 1.5 to 2
        months. As you get this certificate from local authority then in next step you
        will have to send this to AAI or MOD depending on the area and airport
        authority.<br>3. After you submit the AMSL height certificate along with
        required document to Airport Authority, they will verify the entire document and
        will give you final NOC. This whole process will take up to 3 months.</p>

        <p>&nbsp;</p>
      <h3 class="font-medium" >Document required for aviation NOC</h3>

      <p class="text-sm">The document required to issue aviation NOC are mentioned below</p>
      <p class="text-sm">1) Elevation certificate –<br>2) Appendix-<br>3)
        Garbage NOC-<br>4) Drawing of building section including water tank<br>5)
        Drawing of building elevation including water tank</p>
      <h3 class="font-medium">In what scenario the elevation certificate is required?</h3>
      <p class="text-sm">Building anything near the air port is the serious issue. There are certain
        restrictions to build on the land. This certificate is required if your land
        comes under red zone. You have to get the permission from AAI to build on the
        land that comes under red zone. The red zone is less than 6 km from the air
        port. The restriction changes according to color coding map. It is required to
        get NOC for any of the infrastructure that is being built near runway. It
        includes building, chimney, flyover, crane, bill boards, power transmission
        lines wind turbine, etc.</p>
        <p>&nbsp;</p>
      <h3 class="font-medium">Time duration required for aviation NOC?</h3>
     
      <p class="text-sm">The time duration to generate elevation certificate from local authority such
        as___ is one and half month. As soon as you submit this certificate to AAI it
        takes 3 months to get the final NOC.</p>
        <p>&nbsp;</p>
      <h3 class="font-medium" >Cost Involved in generating Aviation NOC</h3>
      <p class="text-sm">The cost involved in getting aviation noc normally cost depends upon authority
        who issue elevation certificate. Elevation certificate cost varies from 16000 rs
        to 40000 rs. For aviation clearance, no government charges required.</p>
      <p>&nbsp;</p>
      <h3 class="font-medium">Is there any validity for this NOC&nbsp; ?&nbsp;</h3>
      <p class="text-sm">Yes, Aviation NOC certificate is valid for 5 Years from date of it’s issuance.
      </p>
      <p>&nbsp;</p>`,
    "largeIcon": "aviation.webp",
    "smallIcon": "aviation.png",
    "metaDescription": "Aviation Noc in Pune, Pcmc &amp; Pmrda. Solution for Defence Noc &amp; Elevation certificate. Check aviation cczm zone, Free Consultation. Check Now.",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Get Aviation NOC for plot in Pune - 50% Discount - Foot2Feet",
    "category": "Legal Services",
    "questions": [{
        "title": "Your plot is close to which airport?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["NDA", "Lohegaon"]
      },
      {
        "title": "Your plot falls under which zone?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Red Zone", "Funnel Zone", "I don't have any idea"]
      },
      {
        "title": "What is the height of your building you want permission for?",
        "type": "text",
        "other": false,
        "otherIndex": -1
      }
    ]
  },
  {
    "url": "fire",
    "displayText": "Fire NOC",
    "id": "fireNOC",
    "descriptionHTML": `
     <ol class="list-decimal">
    <li class="ms-5">
    <p class="text-sm">In India Fire NOC is mandatory for the certain kind of buildings.&nbsp; This
    certificate&nbsp;is&nbsp;a No Objection Certificate indicating, in short&nbsp;
    NOC certificate for fire safety &nbsp;that the building&nbsp;is&nbsp;designed as
    per the norms and regulations that a high-rise building must have to avoid any
    hazardous accidents. It&nbsp;is&nbsp;issued by the&nbsp;Fire department&nbsp;of
    the respective state before the building construction.</p>
    </li>
    <li class="ms-5">
<h2 class="font-medium"> Types of Fire NOC</h2>

<p class="text-sm">Primarily there are two types –</p>
<ul><ol class="list-[upper-roman] ml-10">
    <li class="text-sm">Provisional fire NOC (H4)</li>
    <li class="text-sm">Final Fire NOC</li>
    </ol>
</ul>
<ul class="list-disc">
<li class="ms-5">
        <h3 class="font-medium">Provisional fire NOC</h3>
        <p class="text-sm">This NOC has to be generated long before the construction is started. The
            builder/ applier must issue this at the time of submitting building plan
            to the cooperator. In this procedure the fire plan gets approved from
            the authority. The Provisional certificate states the amenities and
            required fire safety that includes sprinkler, dedicated tank, etc (Full
            information is given below)</p>
    </li>
    <li class="ms-5">
        <h3 class="font-medium">Final Fire NOC:</h3>
        <p class="text-sm">Once the builder is done with the construction, he must apply for the
            final certificate. The final certificate is required to get occupancy
            certificate. In order to get it the officer visits the site and check
            for the apparatus that is sprinkler, fire safety alarms, etc. If the
            sites meet the parameter then the building is ready for the occupancy.
            With all this procedure the constructed building is made safe and fire
            resistant.</p>
        </li>
      </ul>
    </li>
      <li class="ms-5">
      <h3 class="font-medium"> Documents Required for Fire NOC</h3>
      <p class="text-sm">There is whole process to generate the certificate. Here is the process in order
        to generate&nbsp; application around Pune area.</p>
      <ul class="list-disc text-sm">
        <li class="ms-5">Application from the architect to fire department</li>
        <li class="ms-5">Building details from.</li>
        <li class="ms-5">Drawing that shows the safety norms of the building</li>
        <li class="ms-5">7/12 certificate / property card photocopy</li>
      </ul>
      </li>
      <li class="ms-5">
      <h3 class="font-medium"> Process Involved</h3>
      <p class="text-sm">There is whole process that everyone has to follow to get fire certificate.
        Following is the step by step procedure to be followed –</p>
      <ol class="list-[upper-roman] text-sm">
        <li class="ms-5">To apply, the applier need to have proper documents as mentioned above and
          submit it to the respective/Concern fire department. The document must
          contain building drawing.</li>
        <li class="ms-5">The fire officer will survey the site. He will look for the road width and
          plot location to see if it meets the requirement</li>
        <li class="ms-5">The officer then scrutinize whole document and the provisions made in
          drowning</li>
        <li class="ms-5">After the calculation is done the officer will generate the challan receipt
          for fire charges.</li>
        <li class="ms-5">The applier must pay the amount mentioned in challan via cash, cheque, dd or
          RTGS.</li>
        <li class="ms-5">After the successful payment, the applier will receive stamped and singed
          copy of drawing set and The Fire NOC Certificate</li>
        <li class="ms-5">For NOC in PMC&nbsp;<a href="https://pmc.gov.in/en/e-services">https://pmc.gov.in/en/e-services</a>
        </li>
      </ol>
    </li>
    <li class="ms-5">
      <h3 class="font-medium" > Who requires Fire NOC?</h3>
      <p class="text-sm">Any high rise building requires fire certificate.&nbsp; Restaurants, hotel,
        industrial buildings, institutes, educational buildings such as school colleges,
        auditorium, apartments. Regardless of this any building which is taller than 15
        meter from ground i.e. 5 floor or bigger requires fire no objection certificate.
      </p>
      <p class="text-sm">If floor area of any building is more than 500 Sqm (Including staircase, balcony,
        habitable room etc.) then fire NOC for such special building is must</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium">Parameters for the NOC</h3>
      <p class="text-sm">Depend upon height and type of building Fire Department recommend few things to
        install in building, Example,&nbsp; &nbsp;dedicated water tank on the top of the
        building and underground as well. Sometime fire alarms and smoke detector,
        vertical pipes and hose reel etc. in the building. The road width is also
        measured to check if the fire truck can enter the building or not. It is also
        required to have the fire exit stare case within prescribed travel distance from
        any corner of floor.</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium">Time Duration</h3>
      <p class="text-sm">The time duration required for to get the fire NOC is almost 8 days to 15 days.
        In some cases it may extend up to one and half month as well as every building
        has different criteria to satisfy.&nbsp;&nbsp;</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium"> Cost involved to get the NOC</h3>
      <p class="text-sm">Fire premium charges are 8 Rs to 2000 Rs. This varies on height of building for
        example, Pune Corporation Charge –</p>
      <ul class="list-disc text-sm">
        <li class="ms-5">45 Rs per Sqm for height upto 24 Meter.</li>
        <li class="ms-5">75 Rs perSqm for height 24 Meter to 36 Meter.</li>
        <li class="ms-5">1000 Rsper Sqm for height 36 Meter to 40 Meter.</li>
      </ul>
      <p class="text-sm">These charges varies on location, jurisdictory body, use of building and height
        of building.</p>
      <p class="text-sm">For NOC in PMRDA&nbsp;<a href="http://www.pmrda.gov.in/dept-10.html"
          style="font-size: 18px; font-family: var( --e-global-typography-text-font-family ); background-color: rgb(255, 255, 255);">http://www.pmrda.gov.in/dept-10.html</a>
      </p>
      </li>
      <li class="ms-5">
      <h3 class="font-medium"> Who gives this certificate</h3>
      <ul class="list-disc text-sm">
        <li class="ms-5">Any corporation who has fire department eg. Pune Municipal Corporation,
          Pipmri Chinchwad Municipal Corporation</li>
        <li class="ms-5">Special Planning Authorities</li>
        <li class="ms-5">PMRDA, MMRDA Etc.</li>
        <li class="ms-5">Maharashtra Fire Services, Mumbai</li>
      </ul>
      <p class="text-sm">For Nagar Palika and other area which do not come under other fire department</p>
      </li>
      <li class="ms-5">
      <h3 class="font-medium"> How Can We Help?</h3>
      <p class="text-sm">Foot2feet has a knowledgeable team of professionals to take care of all this
        documentation for you. Contact us today to find out how our experts can help you
        in your business. For more details, write to us at admin@foot2feet.com or call
        on our helpline</p>
      <p class="text-sm">+91-82 82 92 42 42 and directly speak to our experts for any inquiry relating to
        the construction indust<a href="https://pmc.gov.in/en/e-services"
          style="font-size: 18px; font-family: var( --e-global-typography-text-font-family ); background-color: rgb(255, 255, 255);">https://pmc.gov.in/en/e-services</a>
      </p>
      </li>`,
    "largeIcon": "fire.webp",
    "smallIcon": "fire.png",
    "metaDescription": "Connect with professionals for Provisional &amp; Final Fire NOC in Pune. Free Consultation. Trusted Service. Call +91 8282924242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Fire NOC for Construction in Pune - At 20% Discount - Foot2Feet",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 4,
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft."]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How many floors does the building contain?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["5-7 floors", "7-10 floors", "10-15 floors", "15 floors and above"]
      }
    ]
  },
  { //Not inserted
    "url": "construction-nocs",
    "displayText": "Construction NOCs",
    "id": "constructionNOCs",
    "largeIcon": "constructionNOCs.webp",
    "smallIcon": "constructionNOCs.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 4,
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft."]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "Which NOC(s) do you require?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Drainage", "Water", "Garden"]
      }
    ]
  },
  {
    "url": "rera",
    "displayText": "Rera",
    "id": "rera",
    "descriptionHTML": `<ol class="list-decimal">

  <p class="text-sm">Under the Real Estate (Regulation and Development) Act, 201, the Parliament of
        India passed a regulation which made it mandatory for each state to have a Real
        Estate Regulatory Authority (RERA), which would benefit both buyers and sellers
        in the real estate sector.<br>RERA builder registration is essential since it
        has had a great impact on the real estate sector as liquidity has been
        tightened. Project costs have increased, and so has the Cost of Capital. Real
        estate has reached a new level of standardization with the introduction and
        application of RERA.</p>
      <h2></h2>
      <h3 class="font-medium"><mark>Rera Consultants</mark></h3>
      <h3 class="font-medium">Why registration is mandatory for builders, brokers &amp; agents ?&nbsp;</h3>
      <h2></h2>
      <p class="text-sm">RERA was introduced to benefit both buyers and builders alike. As a builder, you
        must choose RERA project registration taking into consideration the following
        points:</p>
      <h2></h2>
      <p class="text-sm">
        Buyers will not hesitate in investing in your project since you shall be
          required to deliver the space on time<br>
        You can only advertise your project after your project is registered under
          RERA<br>
        Also you will receive all clearances making your project a suitable choice
          for buyers<br>
        You have the feasibility of complaining about any matter related to the
          project to RERA or the Appellate Tribunal.<br>
        RERA ensures quick dispute resolution with transparency.<br>
        The risk of insolvency is reduced since you shall be required to deposit 70%
          of the total amount of funds collected for the project into a separate bank
          account. However, this amount can only be withdrawn if the architect,
          engineer, and CA agrees to it.<br>
      </p>
      <h2></h2>
      <h3 class="font-medium">Where can I get RERA registration?</h3>
      <h2></h2>
      <p class="text-sm">Rera Consultancy Services and Rera registration is crucial for anyone involved in
        the Real Estate Industry. So, when you come to Foot2Feet.com looking for your
        RERA registration consultant, you shall find a team of professionals ready to
        work with you. We shall take care of the entire process, right from
        documentation to finally acquiring the RERA registration certificate.</p>
      <h2></h2>
      <p class="text-sm">In addition we will get approve your Rera application within 7 days of the
        application with our rera consultancy service. We work for RERA registration in
        pune or rera consultant in Mumbai as well as all major cities in Maharashtra
        like Nagpur, Aurangabad, Solapur, Nanded, Nasik, Kolhapur or all Talukas in
        Maharashtra.</p>
      <h2></h2>
      <p class="text-sm">Other services</p>
      <h2></h2>
      <h3 class="font-medium">Rera Consultants in Mumbai</h3>
      <h3 class="font-medium">What is Rera registration process?</h3>
      <h2></h2>
      <p class="text-sm" >As a promoter (builder, developer, society, development authority), you shall
        have to follow the procedure necessary for RERA registration.</p>
      <h2></h2>
      <p class="text-sm">
        1.Gather all necessary documents with the help of a well-prepared check-list<br>
        2.Open a bank account according to the specifications mentioned in the RERA
        3.  act under Section 4 (2) (I) (D)<br>
        4.Fill and submit Form A for registration<br>
        5.Fill and submit Form B as a declaration that you are a part of the real
        6.  estate industry abiding by RERA regulations<br>
        7.Prepare and submit a draft agreement for the sale and allotment of the
        8.  particular project.<br>
        9.Fee Payment for the state of Maharashtra is minimum of Rs. 10000/- and
        10.  increase as per plot size.<br>
        11.Every document related to sales shall include your unique RERA registration
        12.  number<br>
        13.Maintain proper books of accounts and transactions<br>
      </p>
      <h2></h2>
      <h3 class="font-medium">Documents required for project registration</h3>
      <h2></h2>
      <p class="text-sm">
        1.Builder’s PAN card, Aadhar Card &amp; passport size photograph<br>
        2.Copy of the legal title report<br>
        3.Details of encumbrances<br>
        4.Copy of Layout Approval (In case of layout) and Building plan Approval (OD)<br>
        5.Signed Proforma of the allotment letter and agreement for sale<br>
        6.Declaration in FORM B<br>
        7.Certificate of Architect. (Form 1)<br>
        8.Certificate of CA(Form 3)<br>
        9.Certificate of Engineer (Form 2)<br>
        10.Commencement Certificate<br>
        11.Partnership deed if partnership firm<br>
        12.Self Regulatory Organizations RPM number<br>
      </p>
      <h2></h2>
      <h3 class="font-medium">Documents required for Real Estate Agent Registration</h3>
      <h3 class="font-medium">A) Individual agent Rera Registration documents</h3>
      <h2></h2>
      <p class="text-sm">
        1.Copy of the PAN card     <br>
        2.Aadhaar card Number;<br>
        3.Copy of the address proof;<br>
        4.Copy of ITR for last 3 Years<br>
        5.Basic details of the real estate agent such as name, address, contact
          details, and photographs<br>
      </p>
      <h2></h2>
      <h3 class="font-medium">B) Registration for LLP/Partnership Firm/ Company</h3>
      <h2></h2>
      <p class="text-sm">
        1.Pan Card of Firm / LLP/ Company       <br>
        2.Pan Card of All Partners / Directors<br>
        3.Partnership Deed Copy<br>
        4.Certificate of Incorporation (only for LLP / Company)<br>
        5.Address Proof for all Partners / Directors<br>
        6.Copy of ITR for last 3 Years<br>
        7.Business Address Proof if different from above<br>
        8.MOA &amp; AOA of Copy (For Company)<br>
      </p>
      <h2></h2>
      <h3 class="font-medium"><mark>Rera Registration Consultant</mark></h3>
      <h3 class="font-medium">When Should I Get a RERA registration Number?</h3>
      <h2></h2>
      <p class="text-sm">Under the act, all residential and commercial properties will have to register
        under RERA, especially those which:<br>● Have a land-use of 500 square meters or
        over<br>● Have eight apartments or more<br>If you have an ongoing project that
        has not yet received a Completion Certificate, then you will need to apply for
        RERA registration within three months.</p>
      <h2></h2>
      <h3 class="font-medium">What if I do not register under RERA?</h3>
      <h2></h2>
      <p class="text-sm">RERA registration for builders is mandatory under the act.<br>According to the
        regulations given under RERA, Section 59 states that in case a project is not
        registered then a penalty of up to 10% will be charged based on the estimated
        cost of the said project. In case the promoter decides not to register in spite
        of this fine, an additional 10% of the penalty shall be borne by the promoter.
        This could also come with a three-year imprisonment sentence.<br>Let’s say you
        registered under RERA but gave false information about an aspect related to the
        project, then you shall be charged with a 5% penalty of the estimated cost of
        the project.</p>
      <h2></h2>
      <p class="text-sm">If you wish to avoid such hassles, then consider hiring an expert RERA consultant
        and get Rera consultancy services in Pune from Foot2Feet.</p>
      <h2></h2>
      <p class="text-sm">To read news related to Rera&nbsp;<a
          style="font-weight: inherit; font-family: var( --e-global-typography-text-font-family ); background-color: #ffffff;"
          href="https://economictimes.indiatimes.com/topic/RERA-rules/news" target="_blank" rel="noopener">“Click
          Here”</a></p>
      <h2></h2>
      <h3 class="font-medium"Is there any validity for this registration?&nbsp;</h3>
      <h2></h2>
      <p class="text-sm">You, as a promoter, shall have the upper hand in deciding the validity of your
        RERA registration. This could either be until the completion of the project or a
        period as mentioned in the affidavit.<br>You will be granted extension under the
        following circumstances:<br>● A natural calamity or a situation like the war may
        hinder the development process<br>● If you have given a suitable reason that the
        authority finds acceptable, then a maximum of one-year extension shall be
        provided</p>
      <h2></h2>
      <p class="text-sm">Check Other construction related services on&nbsp;<a href="https://foot2feet.com/construction-services/"
          target="_blank" rel="noopener">Foot2feet</a></p>`,
    "largeIcon": "rera.webp",
    "smallIcon": "rera.png",
    "metaDescription": "As all documents are prepared by us. Hence your Maha Rera registration certificate is generated in mimimum time. Lowest charges. Hussle free service.",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Quick Rera Registration | At 50% Discount | Foot2Feet",
    "category": "Legal Services",
    "questions": [{
        "title": "How much is the plot area?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["5000-10000 sq.ft", "10000 – 15000 sq.ft.", "15000 and above"]
      },
      {
        "title": "Do you have a sanction plan?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Yes", "No"]
      },
      {
        "title": "How many units are there in total (including shops, offices, and flats)?",
        "type": "text"
      }
    ]
  },
  {
    "url": "tdr-buy-sell-road-handover",
    "displayText": "TDR Buy Sell Road Handover",
    "id": "tDRBuySellRoadHandover",
    "descriptionHTML": "",
    "largeIcon": "tDRBuySellRoadHandover.webp",
    "smallIcon": "tDRBuySellRoadHandover.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much TDR do you want to sell/require in total (including slum and regular TDR)?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Less than 500 sq.ft", "500 - 1000 sq.ft", "1000 - 3000 sq.ft", "3000 sq.ft. and above"]
      },
      {
        "title": "How likely are you to make the selling/buying decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  {
    "url": "tdr-generation",
    "displayText": "TDR Generation",
    "id": "tDRGeneration",
    "descriptionHTML": ` <ol class="list-decimal">
  <p class="text-sm">If any land is affected by reservation of amenity, road, garden
        or any other reservation then that land has to be surrendered to respective
        authority. After handover of the land area the authority gives development right
        certificate as compensation instead of money. These rights are called TDR
        (Transferable Development Rights). It can be sold to people who want to do
        additional construction on their land. This way government can save huge money
        in acquisition of land and the land owners have no loss as he gets compensation
        for this. Usually for municipal corporations like PMC, PCMC &amp; special
        planning authority like PMRDA follow this system.</p>
        <li class="ms-5">
      <h3 class="font-medium"> Which are 4 types of TDR service on Foot2Feet?</h3>
      <h3 class="font-normal">
        1.Generation of TDR –</h3>
      <p class="text-sm" style="text-indent: -.25in; mso-list: l1 level1 lfo1;"> This is process of handing over reservation land to corporation and
        procuring TDR certificate from them. This may take 6 to 12 months</p>
        <h3 class="font-normal">
        2.Utilization of TDR –</h3>
      <p class="text-sm" style="text-indent: -.25in; mso-list: l1 level1 lfo1;"> This is process in which TDR purchased from land owner is loaded on the new development land. (this may take 1 month )</p>
        <h3 class="font-normal">
        3. Buying  of TDR – </h3>
      <p class="text-sm" style="text-indent: -.25in; mso-list: l1 level1 lfo1;">In this builder or developer f land buy TDR from TDR owner. This process is somewhat similar to land buying process. This do not take much time.</p>
      <h3 class="font-normal">
      4.Sell TDR – </h3>
      <p class="text-sm" style="text-indent: -.25in; mso-list: l1 level1 lfo1;">
      Similar to buying, in this TDR is sold by one party.</p>
      </li>
      <li class="ms-5">
      <h3 class="font-medium"> What is the process for Utilization of TDR in
          Pune?</h3>
      <p class="text-sm">Loading of TDR on any plot called TDR utilization process.
        There are certain rules for loading of TDR on any plot or land. This is given in
        proportion of plot area. Eg – on 9 meter wide road in Pune developer can
        purchase 40% of TDR means he can purchase &amp; utilize TDR equal to 40% X plot
        area.</p>
      <p class="text-sm">Foot2feet have developed FSI &amp; TDR calculator to find TDR
        capacity of any land.&nbsp;<a
          href="https://foot2feet.com/home/smart-tools/construction-calculators/fsi-calculator" target="_blank">Click
          here</a>&nbsp;to check TDR loading capacity &amp; FSI
        calculation of your plot.&nbsp;</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium">What id TDR Indexing?</h3>
      <p class="text-sm">TDR from 2016 is associated with ready reckoner rate of land.
        It means if an owner purchase 100 Square meter TDR generated on a land of rate
        10,000 Rs per SQM, and he want to utilize on plot of rate 5,000 RS, then 100 SqM
        TDR will be considered as200 SQM TDR due torate difference betweenland rate of
        both the land. You can check Ready Reckoner Rate&nbsp;<a
          href="http://www.igrmaharashtra.gov.in/eASR/frmMap.aspx" target="_blank">here</a>.&nbsp;</p>
      <p class="text-sm">Foot2Feet is about to develop TDR indexing calculator.&nbsp;<a
          href="https://foot2feet.com/home/smart-tools/construction-calculators" target="_blank">Check </a>its
        availability.&nbsp;&nbsp;</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium"> What are Types of TDR?</h3>
      <h3 class="text-sm">Regular TDR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; –
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
      <p class="text-sm">TDR generated from road or any DP reservation is called as
        regular tdr.</p>
      <h3 class="text-sm">Slum
        TDR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        –&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
      <p class="text-sm">&nbsp;TDR generated from SRA scheme &amp; slums is called as
        slum TDR.</p>
      <p class="text-sm">For utilization of any amount of TDR FSI on any plot in pune ,
        minimum 20% slum TDR is compulsory.</p>
      <p class="text-sm">Means any owner hve to purchase 80 % regular tdr&amp; 20 slum
        TDR.</p>
      </li>
      <li class="ms-5">
      <h3 class="font-medium"> What is TDR in construction field?</h3>
      <p class="text-sm">TDR means Transferable Development Rights.</p>
      <p class="text-sm">Transferable Development Right (TDR) certificate (D.R.C.) is
        issued after handing over land reserved land in DP for&nbsp; public purpose to
        PMC instead of cash compensation. Where in TDR given (in the form of FSI) and
        TDR utilization details are recorded.&nbsp; DRC holder can use such FSI for self
        or can sell it to others.</p>
      <p class="text-sm">In TDR trading, price is primarily based on demand and supply
        concept. Due to the TDR trading, the real estate prices are increasing day by
        day. TDR is a certificate obtained from the Municipal Corporation which an owner
        of the property obtains where his property is reserved for the purpose of public
        utilities such as road, garden, school, etc.</p>
      </li>
        <li class="ms-5">
      <h3 class="font-medium"> Who provide&nbsp; “TDR GENERATION / UTILIZATION”
          services?</h3>
      <p class="text-sm">TDR Service providers by&nbsp; Liasoning Architect or
        Liaisoning Professionals provide this service. Foot2Feet have best vendors
        registered who provide service in very low rate.</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium">Why TDR Certificate is required?</h3>
      <p class="text-sm">The TDR certificate is required for reserved rights obtained by
        the Owner on Surrendering his property to the Municipal Corporation.</p>
      <p class="text-sm">Municipal corporation provides additional built-up area instead
        of area surrendered by the owner of the land so that the extra built-up area can
        be used by owner in an optimum manner</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium"> Cost for generation of TDR?</h3>
      <p class="text-sm">Charges for generation of TDR varies from 250 – 350 RS per
        sq.ft. Professionals registered with Foot2Feet will always give you assured best
        rate.</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium"> What is transfer of development rights?</h3>
      <p class="text-sm">TDR are usually transferred from fully developed zones to other
        zones. The basic motive behind this transfer is to facilitate the development of
        the under developed areas.</p>
      <p class="text-sm">As per explanation I to Rule 5 of the MAHARERA Rules, 2017, the
        TDR Acquisition Cost is included while calculating the land cost, therefore, it
        finally increases the property prices. It has come up as an urban development
        tool which has resulted in the congestion in a haphazard manner. This concept
        came up when there used to be land acquisition by the Government authorities for
        the formation of Roads, civic amenities, etc. TDR certificate minimizes the Time
        needed and easily helps in the process of acquisition as the owner can transfer
        his rights with the help of this certificate.</p>
        </li>
        <li class="ms-5">
      <h3 class="font-medium"> What are the Document Required for&nbsp; “TDR
          &nbsp;generation”?</h3>
      <p class="text-sm">Here is list of documents for making of TDR of reservation
        land.</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        1.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->T.D. R.&nbsp; Book and real estate management</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        2.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->7/12 Extract / Property Card</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        3.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->All Ferfar</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        4.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->B Form and TP Scheme Sight Plan (if available in TP Scheme)</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        5.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Demarcation certified copy</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        6.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Falninakasha</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        7.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->&nbsp;Letter of Excess Certificate in case of non-availability of
        Fertilizer</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        8.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Gaonnakasha (village map)</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        9.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Site Plan</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        10.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Development &nbsp;Plan of 1987</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        11.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Percentage (A to R) site Plan (within the limit)</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        12.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->&nbsp;Zoning Demarcation</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        13.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Zone Certificate</p>
      <p class="text-sm"
        style="margin-left: 20.25pt; mso-add-space: auto; text-indent: -.25in; mso-list: l0 level1 lfo2;">
        14.<span
          style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 'Times New Roman';">
        </span>
        <!--[endif]-->Google Images</p>
      <p class="MsoListParagraphCxSpLast" style="margin-left: 20.25pt; mso-add-space: auto;">There are few more
        documents
        which change as per plot location and reservation type.</p>
        </li>`,
    "largeIcon": "tDRGeneration.webp",
    "smallIcon": "tDRGeneration.png",
    "metaDescription": "Get best rates for TDR in Pune &amp; PCMC. Assured best rates. Get all services like TDR Geneartion, Utilisation, Buy, Sell, Load. Call 8282924242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Transferable Development Right - TDR l Buy at 68% Discount l Foot2Feet",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the reservation on your plot?",
        "type": "radio",
        "options": ["Road", "Garden", "School", "Playground", "Other (Please Specify)"]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": ["Still in enquiry stage", "Will hire immediately", "Not sure", "Will hire within a month"]
      }
    ]
  },
  { //Photo
    "url": "7-12",
    "displayText": "7/12, Property Card",
    "id": "7-12PropertyCard",
    "descriptionHTML": "",
    "largeIcon": "propertycard.webp",
    "smallIcon": "propertycard.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 4,
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What service you require related to 7/12 or property card?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Need original copy with Talathi sign and stamp",
          "Correction in name",
          "Addition/Deletion of name",
          "Correction in area"
        ]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Still in enquiry stage",
          "Will hire immediately",
          "Not sure",
          "Will hire within a month"
        ]
      }
    ]
  },
  { //Photo
    "url": "property-tax",
    "displayText": "Property Tax",
    "id": "propertyTax",
    "largeIcon": "propertyTax.webp",
    "smallIcon": "propertyTax.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Legal Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "You want to pay tax of last how many years?",
        "type": "radio",
        "options": [
          "1 year",
          "2 years",
          "3 years",
          "4 years",
          "5 years",
          "More than 5 years"
        ]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Still in enquiry stage",
          "Will hire immediately",
          "Not sure",
          "Will hire within a month"
        ]
      }
    ]
  },
  { //Photo
    "url": "deep-cleaning-construction-site",
    "displayText": "Deep Cleaning Construction Site",
    "id": "deepCleaningConstructionSite",
    "descriptionHTML": "",
    "largeIcon": "deepCleaningOfConstructionSite.webp",
    "smallIcon": "deepCleaningOfConstructionSite.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What type of cleaning you require?",
        "type": "radio",
        "options": [
          "Acid wash",
          "Floor cleaning",
          "Floor polishing",
          "Polishing of rusty taps and rods",
          "All of the above"
        ]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Still in enquiry stage",
          "Will hire immediately",
          "Not sure",
          "Will hire within a month"
        ]
      }
    ]
  },
  { //Photo
    "url": "solar-panel",
    "displayText": "Solar Panel",
    "id": "solarPanel",
    "descriptionHTML": "",
    "largeIcon": "solarPanel.webp",
    "smallIcon": "solarPanel.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "You require solar panels for?",
        "type": "radio",
        "options": [
          "Generate electricity",
          "Hot water"
        ]
      },
      {
        "title": "How likely are you to make the hiring decision?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Still in enquiry stage",
          "Will hire immediately",
          "Not sure",
          "Will hire within a month"
        ]
      }
    ]
  },
  { //Photo
    "url": "rain-water-harvesting",
    "displayText": "Rain Water Harvesting",
    "id": "rainWaterHarvesting",
    "descriptionHTML": "",
    "largeIcon": "rainWaterHarvesting.webp",
    "smallIcon": "rainWaterHarvesting.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the area of plot?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 1000 sq.m.",
          "1000 - 3000 sq.ft",
          "3000 sq.m. and above"
        ]
      },
      {
        "title": "What do you require?",
        "type": "radio",
        "options": [
          "Rain water harvesting System installation",
          "Rain Water Harvesting NOC",
          "Both"
        ]
      }
    ]
  },
  { //Photo
    "url": "vermiculture",
    "displayText": "Vermiculture",
    "id": "vermiculture",
    "descriptionHTML": "",
    "largeIcon": "vermiculture.webp",
    "smallIcon": "vermiculture.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the area of plot?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 1000 sq.m.",
          "1000 - 3000 sq.ft",
          "3000 sq.m. and above"
        ]
      },
      {
        "title": "How many total tenements are there?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 10",
          "10 - 100 Tenements",
          "100 - 300 Tenements",
          "300 and above"
        ]
      }
    ]
  },
  { //Photo
    "url": "compound-fencing",
    "displayText": "Compound Fencing",
    "id": "compoundFencing",
    "largeIcon": "compoundFencing.webp",
    "smallIcon": "compoundFencing.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the area of plot?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 1000 sq.m.",
          "1000 - 3000 sq.ft",
          "3000 sq.m. and above"
        ]
      },
      {
        "title": "What type of fencing do you want?",
        "type": "radio",
        "options": [
          "Iron Galvanised Wire Fencing",
          "M S Pipe fencing service",
          "Commercial Galvanised Wire Fencing",
          "10’ Commercial Sheet fencing",
          "Electronic fence management System"
        ]
      }
    ]
  },
  { //Photo
    "url": "water-proofing",
    "displayText": "Water Proofing",
    "id": "waterProofing",
    "largeIcon": "waterProofing.webp",
    "smallIcon": "waterProofing.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the area of plot?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 1000 sq.m.",
          "1000 - 3000 sq.ft",
          "3000 sq.m. and above"
        ]
      },
      {
        "title": "What type of waterproofing service do you require?",
        "type": "radio",
        "options": [
          "Polyurethane",
          "Cementitious coating",
          "Rubberized Asphalt Coating",
          "PVC waterproofing membrane",
          "Bituminous membrane"
        ]
      }
    ]
  },
  { //Photo
    "url": "planting-agency",
    "displayText": "Planting Agency",
    "id": "plantingAgency",
    "largeIcon": "plantingAgency.webp",
    "smallIcon": "plantingAgency.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "How much is the area to be landscaped?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 200 sq.ft",
          "200 – 500 sq.ft",
          "500-1000 sq.ft",
          "Above 1000 sq.ft."
        ]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "What is your requirement specifically?",
        "type": "radio",
        "options": [
          "Vertical Garden",
          "Trees, Plants, and Shrubs",
          "Garden Supplies"
        ]
      }
    ]
  },
  { //Photo
    "url": "grill-and-jali",
    "displayText": "Grill & Jali",
    "id": "grillAndJali",
    "descriptionHTML": "",
    "largeIcon": "grillAndJali.webp",
    "smallIcon": "grillAndJali.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
          "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
          "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
          "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
          "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
          "Multiplex", "Cinema Theater", "Industrial Building"
        ]
      },
      {
        "title": "How much is the area of Jali?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 200 sq.ft",
          "200 – 500 sq.ft",
          "500-1000 sq.ft",
          "Above 1000 sq.ft."
        ]
      }
    ]
  },
  { //Photo
    "url": "swimming-pool",
    "displayText": "Swimming Pool",
    "id": "swimmingPool",
    "largeIcon": "swimmingPool.webp",
    "smallIcon": "swimmingPool.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area of Pool?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 200 sq.ft",
          "200 – 500 sq.ft",
          "500-1000 sq.ft",
          "Above 1000 sq.ft."
        ]
      }
    ]
  },
  { //Photo
    "url": "excavation",
    "displayText": "Excavation",
    "id": "excavation",
    "largeIcon": "excavation.webp",
    "smallIcon": "excavation.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area of Excavation?",
        "type": "radio",
        "other": false,
        "otherIndex": -1,
        "options": [
          "Less than 200 sq.ft",
          "200 – 500 sq.ft",
          "500-1000 sq.ft",
          "Above 1000 sq.ft."
        ]
      }
    ]
  },
  { //Photo
    "url": "fabrication",
    "displayText": "Fabrication",
    "id": "fabrication",
    "largeIcon": "fabrication.webp",
    "smallIcon": "fabrication.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area of fabrication?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo
    "url": "crane",
    "displayText": "Crane",
    "id": "crane",
    "largeIcon": "crane.webp",
    "smallIcon": "crane.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo
    "url": "lift",
    "displayText": "Lift",
    "id": "lift",
    "descriptionHTML": "",
    "largeIcon": "lift.webp",
    "smallIcon": "lift.png",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo
    "url": "door",
    "displayText": "Door",
    "id": "door",
    "largeIcon": "door.webp",
    "smallIcon": "door.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo
    "url": "window",
    "displayText": "Window",
    "id": "window",
    "largeIcon": "window.webp",
    "smallIcon": "window.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo
    "url": "painting",
    "displayText": "Painting",
    "id": "painting",
    "largeIcon": "painting.webp",
    "smallIcon": "painting.png",
    "descriptionHTML": "",
    "metaDescription": "",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "",
    "category": "Other Services",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "options": ["PMC", "PMRDA", "PCMC", "Mumbai", "Other (Please Specify)"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "How much is the area?",
        "type": "text",
        "subtype": "number"
      }
    ]
  },
  { //Photo and Content
    "url": "civil-contractor",
    "displayText": "Civil Contractor",
    "id": "civilContractor",
    "descriptionHTML": `<ol class="list-decimal">
  <h2 class="font-medium">Factors to consider while selecting Civil Contractor</h2>
    <p class="text-sm">Choosing a civil contractor from so many construction companies&nbsp; is
    the most important factor. You need to go through many things while selecting
    the best civil contractor near you. For example, Background check, certification
    check, quality of construction, track record of previous work etc</p>
<br>
<h2 class="font-medium">What is the role of a general contractor ?</h2>
<p class="text-sm">A general contractor is responsible to provide all the materials, labour, tools ,
    equipments required on site from time to time. However a civil contractor in
    Pune often hires specialized sub contractors to carry out different activities
    in the construction work.</p>
<br>

<h2 class="font-medium">FAQ About Civil Contractor&nbsp;</h2>
<ol class="list-decimal">
<li class="ms-5">
    <h3>What are the 4 types of constructions?</h3>
<p class="text-sm ">Majorly, Construction of building falls in below types-</p>
<ul class="list-disc text-sm">
<li class="ms-5">Residential Building.</li>
<li class="ms-5">Commercial and Institutional Building</li>
<li class="ms-5">Industrial Building</li>
<li class="ms-5">Infrastructure Building</li>
</ul>
</li>
<li class="ms-5">
<h3>Is it cheaper to build a home than buy it?</h3>
<p class="text-sm">Yes!! It is cheaper to build a home than buy it in your expected budget. In other
    words you can build it on your own or hire a construction company to help you
    out.</p>
</li>
<li class="ms-5">

<h3 >How do you calculate construction Cost?</h3>
<p class="text-sm">You can get free estimates from contractor to know the construction cost. Here is
    a simple formula to calculate construction cost<br>Cost of construction = Area
    of plot x Construction rate per sqft<br></p>

</li>


<li class="ms-5">
<h3 >What are the differences between Civil Engineer and General
        Contractor ?</h3>

<p class="text-sm">Civil engineer are people who design the project. Whereas general contractors are
    the people who takes on a project for implementing the designers specification
    in construction. However You can find Civil work contractor and general
    contractor on foot2feet.<br> Check PMC registered contractors list
    at;<a
        href="https://www.pmc.gov.in/en/pmc-registered-contractor-list">https://www.pmc.gov.in/en/pmc-registered-contractor-list</a>
</p>

</li>

<li class="ms-5">
<h3>Do contractors give free estimates?</h3>

<p class="text-sm">Depend wise! Generally Construction companies near you offer a free estimate or
    charge as per depending on the project.</p>

</li>
</ol>

      <p style="margin-left: 1.0in; mso-add-space: auto; text-indent: -.25in; mso-list: l1 level1 lfo1;">
      </p>`,
    "largeIcon": "civilContractor.webp",
    "smallIcon": "civilContractor.png",
    "metaDescription": "Find Civil Contractors for construction in Pune at affordable price. Trusted and Quick Service. Any Query Call +91 8282924242",
    "metaKeywords": "",
    "ogurl": "",
    "metaTitle": "Best Civil Contractor for Building Construction in Pune - Foot2Feet",
    "category": "Contractors",
    "questions": [{
        "title": "What is the Location of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Pune", "Mumbai", "Other"]
      },
      {
        "title": "What is the Category of Project?",
        "type": "radio",
        "other": true,
        "otherIndex": 2,
        "options": ["Consultants", "Contractors", "Other Services", "Legal Services"]
      },
      {
        "title": "What is the type of project?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Row House", "Banglow", "Banglow - Semi Datached", "Residential Building (Upto G+2ORP3)", "Residential With Shops / Office On Ground",
        "Any Residential Building", "Hostel", "Residential With Shops / With Use", "Residential With Offices / Mixuse", "Any Commercial Building",
        "Office Building With Shop", "Office Building Without Shop", "Hotel", "Restaurant", "Resort", "Public Building", "Semi Public Building",
        "Institutional", "Preprimary School", "Primary School", "Secondary School", "Collage Building", "Any Other Educational Building", 
        "Health Club Or Clinic", "Maternity Homes", "Hospital", "Any Other Medical Building", "Mangal Karyalaya", "Assembly Hall", "Shopping Mall",
        "Multiplex", "Cinema Theater", "Industrial Building"
      ]
      },
      {
        "title": "What type of contractor you require?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Brick work", "RCC", "Renovation", "Labour", "Fire contractor", "Electrical contractor", "Plumbing", "Tiling", "Demolition"]
      },
      {
        "title": "How much is the quantity of work?",
        "type": "radio",
        "other": false,
        "otherIndex": 0,
        "options": ["Less than 2000 sq.ft", "2000 – 5000 sq.ft", "5000-10000 sq.ft", "Above 10000 sq.ft"]
      }
    ]
  },

]

export {
  Services
};
