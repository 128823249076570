import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  // private socket: Socket
  constructor() { 
    
  }

  online(userId:any){
    // this.socket.emit('online', userId);
  }

  offline(userId:any){
    // this.socket.emit('offline', userId);
  }
}
